import React, { useEffect } from 'react';
import { PartyPopper, Check } from 'lucide-react';

interface SaleCelebrationProps {
  amount: number;
  onClose: () => void;
}

const SaleCelebration: React.FC<SaleCelebrationProps> = ({ amount, onClose }) => {
  useEffect(() => {
    // Simple confetti effect using CSS animations
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 text-center max-w-sm mx-4 animate-bounce-slow relative overflow-hidden">
        {/* Celebration Animation */}
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute top-0 left-1/4 w-2 h-8 bg-yellow-400 animate-confetti-1" />
          <div className="absolute top-0 left-1/2 w-2 h-8 bg-primary-400 animate-confetti-2" />
          <div className="absolute top-0 right-1/4 w-2 h-8 bg-green-400 animate-confetti-3" />
        </div>

        <div className="mb-4 flex justify-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center animate-pulse">
            <PartyPopper className="w-8 h-8 text-green-600" />
          </div>
        </div>
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Congratulations!
        </h2>
        <div className="text-lg text-gray-600 mb-4">
          Sale completed for
          <span className="font-bold text-green-600 block text-2xl">
            ${amount}
          </span>
        </div>
        <div className="flex items-center justify-center gap-2 text-green-600 mb-6">
          <Check className="w-5 h-5" />
          <span>Transaction Successful</span>
        </div>
        <button
          onClick={onClose}
          className="w-full px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SaleCelebration;