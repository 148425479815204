import { v4 as uuidv4 } from 'uuid';

export const getPosts = () => [
  // Vehicles
  {
    id: uuidv4(),
    title: "2020 Tesla Model 3 Long Range",
    price: 39999,
    description: "Excellent condition Tesla Model 3 with Full Self-Driving capability. White exterior, black interior, 38k miles.",
    images: ["https://images.unsplash.com/photo-1560958089-b8a1929cea89?auto=format&fit=crop&w=600&q=80"],
    location: "San Francisco, CA",
    category: "Vehicles",
    rating: 4.9,
    reviews: 15,
    views: 342,
    comments: 8,
    seller: {
      id: 'seller_vehicles1',
      name: "Michael Chen",
      rating: 4.9,
      reviews: 28,
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e"
    }
  },

  // Furniture
  {
    id: uuidv4(),
    title: "Mid-Century Modern Sofa",
    price: 899,
    description: "Beautiful velvet sofa in emerald green. Solid wood frame, excellent condition, pet-free home.",
    images: ["https://images.unsplash.com/photo-1555041469-a586c61ea9bc?auto=format&fit=crop&w=600&q=80"],
    location: "Portland, OR",
    category: "Furniture",
    rating: 4.8,
    reviews: 12,
    views: 245,
    comments: 6,
    seller: {
      id: 'seller_furniture1',
      name: "Sarah Williams",
      rating: 4.7,
      reviews: 19,
      avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330"
    }
  },

  // Clothing
  {
    id: uuidv4(),
    title: "Vintage Levi's 501 Jeans",
    price: 85,
    description: "Original vintage Levi's 501 jeans from the 90s. Perfect condition, size 32x34.",
    images: ["https://images.unsplash.com/photo-1542272604-787c3835535d?auto=format&fit=crop&w=600&q=80"],
    location: "Brooklyn, NY",
    category: "Clothing",
    rating: 4.7,
    reviews: 8,
    views: 178,
    comments: 4,
    seller: {
      id: 'seller_clothing1',
      name: "Alex Rodriguez",
      rating: 4.8,
      reviews: 23,
      avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d"
    }
  },

  // Electronics
  {
    id: uuidv4(),
    title: "MacBook Pro M1 Pro 16-inch",
    price: 1899,
    description: "2021 MacBook Pro with M1 Pro chip, 16GB RAM, 512GB SSD. Perfect condition with AppleCare+.",
    images: ["https://images.unsplash.com/photo-1517336714731-489689fd1ca8?auto=format&fit=crop&w=600&q=80"],
    location: "Austin, TX",
    category: "Electronics",
    rating: 5.0,
    reviews: 21,
    views: 456,
    comments: 12,
    seller: {
      id: 'seller_electronics1',
      name: "David Park",
      rating: 4.9,
      reviews: 45,
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e"
    }
  },

  // Gaming
  {
    id: uuidv4(),
    title: "PlayStation 5 Digital Edition",
    price: 449,
    description: "Brand new PS5 Digital Edition, unopened. Includes controller and all original accessories.",
    images: ["https://images.unsplash.com/photo-1606813907291-d86efa9b94db?auto=format&fit=crop&w=600&q=80"],
    location: "Seattle, WA",
    category: "Gaming",
    rating: 4.9,
    reviews: 17,
    views: 389,
    comments: 9,
    seller: {
      id: 'seller_gaming1',
      name: "Emily Chen",
      rating: 4.8,
      reviews: 31,
      avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80"
    }
  },

  // Real Estate
  {
    id: uuidv4(),
    title: "Modern Downtown Loft",
    price: 2500,
    description: "Beautiful 1BR loft in downtown area. 800 sq ft, hardwood floors, large windows, updated kitchen.",
    images: ["https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?auto=format&fit=crop&w=600&q=80"],
    location: "Chicago, IL",
    category: "Real Estate",
    rating: 4.8,
    reviews: 9,
    views: 267,
    comments: 5,
    seller: {
      id: 'seller_realestate1',
      name: "James Wilson",
      rating: 4.9,
      reviews: 27,
      avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e"
    }
  },

  // Home & Garden
  {
    id: uuidv4(),
    title: "Organic Herb Garden Kit",
    price: 49,
    description: "Complete herb garden starter kit with 6 varieties of organic seeds, pots, and soil.",
    images: ["https://images.unsplash.com/photo-1466692476868-aef1dfb1e735?auto=format&fit=crop&w=600&q=80"],
    location: "Denver, CO",
    category: "Home & Garden",
    rating: 4.7,
    reviews: 14,
    views: 198,
    comments: 7,
    seller: {
      id: 'seller_garden1',
      name: "Lisa Thompson",
      rating: 4.8,
      reviews: 22,
      avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330"
    }
  },

  // Sports
  {
    id: uuidv4(),
    title: "Trek Marlin 7 Mountain Bike",
    price: 799,
    description: "2022 Trek Marlin 7 mountain bike, size L. Excellent condition, recently serviced.",
    images: ["https://images.unsplash.com/photo-1576435728678-68d0fbf94e91?auto=format&fit=crop&w=600&q=80"],
    location: "Boulder, CO",
    category: "Sports",
    rating: 4.8,
    reviews: 11,
    views: 234,
    comments: 6,
    seller: {
      id: 'seller_sports1',
      name: "Ryan Martinez",
      rating: 4.7,
      reviews: 16,
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e"
    }
  },

  // Tools
  {
    id: uuidv4(),
    title: "DeWalt Power Tool Set",
    price: 499,
    description: "20V MAX DeWalt 5-tool combo kit. Includes drill, impact driver, saw, light, and batteries.",
    images: ["https://images.unsplash.com/photo-1581147036324-c1a27bda7161?auto=format&fit=crop&w=600&q=80"],
    location: "Phoenix, AZ",
    category: "Tools",
    rating: 4.9,
    reviews: 19,
    views: 312,
    comments: 8,
    seller: {
      id: 'seller_tools1',
      name: "Tom Anderson",
      rating: 4.9,
      reviews: 34,
      avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e"
    }
  },

  // Baby & Kids
  {
    id: uuidv4(),
    title: "UPPAbaby Vista V2 Stroller",
    price: 699,
    description: "2021 UPPAbaby Vista V2 stroller in Gregory (grey). Includes bassinet and toddler seat.",
    images: ["https://images.unsplash.com/photo-1591348278863-a8fb3887e2aa?auto=format&fit=crop&w=600&q=80"],
    location: "Boston, MA",
    category: "Baby & Kids",
    rating: 4.8,
    reviews: 13,
    views: 267,
    comments: 7,
    seller: {
      id: 'seller_baby1',
      name: "Jessica Lee",
      rating: 4.8,
      reviews: 25,
      avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330"
    }
  },

  // Music
  {
    id: uuidv4(),
    title: "Fender Stratocaster Electric Guitar",
    price: 899,
    description: "2020 Fender American Professional II Stratocaster. Miami Blue, maple neck, mint condition.",
    images: ["https://images.unsplash.com/photo-1564186763535-ebb21ef5277f?auto=format&fit=crop&w=600&q=80"],
    location: "Nashville, TN",
    category: "Music",
    rating: 4.9,
    reviews: 16,
    views: 289,
    comments: 9,
    seller: {
      id: 'seller_music1',
      name: "Chris Taylor",
      rating: 4.9,
      reviews: 29,
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e"
    }
  },

  // Watches
  {
    id: uuidv4(),
    title: "Omega Seamaster Professional",
    price: 3999,
    description: "Omega Seamaster Professional 300M. Blue dial, full kit, excellent condition.",
    images: ["https://images.unsplash.com/photo-1523170335258-f5ed11844a49?auto=format&fit=crop&w=600&q=80"],
    location: "Miami, FL",
    category: "Watches",
    rating: 5.0,
    reviews: 22,
    views: 445,
    comments: 11,
    seller: {
      id: 'seller_watches1',
      name: "Daniel Kim",
      rating: 4.9,
      reviews: 41,
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e"
    }
  },

  // Shoes
  {
    id: uuidv4(),
    title: "Nike Air Jordan 1 Retro High OG",
    price: 299,
    description: "Brand new Air Jordan 1 Retro High OG 'University Blue'. Size US 10. Never worn, comes with original box and extra laces.",
    images: ["https://images.unsplash.com/photo-1615420830426-a3484174cf1c?auto=format&fit=crop&w=600&q=80"],
    location: "Brooklyn, NY",
    category: "Shoes",
    rating: 4.9,
    reviews: 24,
    views: 412,
    comments: 15,
    seller: {
      id: 'seller_shoes1',
      name: "Marcus Thompson",
      rating: 4.9,
      reviews: 156,
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e"
    }
  },

  // Collectibles
  {
    id: uuidv4(),
    title: "Rare Magic: The Gathering Black Lotus Card",
    price: 15999,
    description: "Authentic Alpha Black Lotus card in Near Mint condition. PSA graded 9.0. One of the most valuable and iconic Magic: The Gathering cards ever printed. Includes certificate of authenticity and protective case.",
    images: ["https://images.unsplash.com/photo-1593634804965-0394d1324bc4?auto=format&fit=crop&w=600&q=80"],
    location: "Seattle, WA",
    category: "Collectibles",
    rating: 5.0,
    reviews: 31,
    views: 892,
    comments: 27,
    seller: {
      id: 'seller_mtg',
      name: "Daniel Morgan",
      rating: 4.9,
      reviews: 124,
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e"
    }
  }
];