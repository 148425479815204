import React from 'react';
import { Home, Heart, Plus, MessageSquare, User } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFavoriteStore } from '../store/favoriteStore';
import { useAuthStore } from '../store/authStore';
import toast from 'react-hot-toast';

interface MobileNavProps {
  onPostClick: () => void;
  onMessageClick: () => void;
}

const MobileNav: React.FC<MobileNavProps> = ({ onPostClick, onMessageClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { favorites } = useFavoriteStore();
  const { isAuthenticated } = useAuthStore();

  const handleHomeClick = () => {
    if (location.pathname === '/') {
      // If already on home page, just refresh the content
      window.location.reload();
    } else {
      // Navigate to home page
      navigate('/', { replace: true });
    }
  };

  const handleAuthenticatedAction = (action: () => void) => {
    if (!isAuthenticated) {
      toast.error('Please sign in to continue');
      return;
    }
    action();
  };

  return (
    <nav className="fixed-bottom-nav">
      <div className="grid grid-cols-5 items-center py-2">
        <button
          onClick={handleHomeClick}
          className={`flex flex-col items-center justify-center w-full ${
            location.pathname === '/' ? 'text-primary-600' : 'text-gray-600'
          }`}
        >
          <Home className="h-6 w-6" />
          <span className="text-xs mt-1">Home</span>
        </button>

        <button
          onClick={() => handleAuthenticatedAction(() => navigate('/saved-items'))}
          className={`flex flex-col items-center justify-center w-full relative ${
            location.pathname === '/saved-items' ? 'text-primary-600' : 'text-gray-600'
          }`}
        >
          <Heart className="h-6 w-6" />
          <span className="text-xs mt-1">Saved</span>
          {favorites.length > 0 && (
            <span className="absolute top-0 right-1/4 bg-red-500 text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
              {favorites.length}
            </span>
          )}
        </button>

        <button
          onClick={() => handleAuthenticatedAction(onPostClick)}
          className="flex flex-col items-center justify-center w-full"
        >
          <div className="bg-primary-600 p-3 rounded-full -mt-8 mb-1">
            <Plus className="h-6 w-6 text-white" />
          </div>
          <span className="text-xs text-gray-600">Sell</span>
        </button>

        <button
          onClick={() => handleAuthenticatedAction(onMessageClick)}
          className={`flex flex-col items-center justify-center w-full ${
            location.pathname === '/messages' ? 'text-primary-600' : 'text-gray-600'
          }`}
        >
          <MessageSquare className="h-6 w-6" />
          <span className="text-xs mt-1">Messages</span>
        </button>

        <button
          onClick={() => handleAuthenticatedAction(() => navigate('/profile'))}
          className={`flex flex-col items-center justify-center w-full ${
            location.pathname === '/profile' ? 'text-primary-600' : 'text-gray-600'
          }`}
        >
          <User className="h-6 w-6" />
          <span className="text-xs mt-1">Profile</span>
        </button>
      </div>
    </nav>
  );
};

export default MobileNav;