import React from 'react';
import { categories } from '../CategoryBar';

interface CategorySelectProps {
  category: string;
  subcategory: string;
  onChange: (category: string, subcategory: string) => void;
}

const subcategories: Record<string, string[]> = {
  'Electronics': [
    'Phones', 'Computers', 'TVs', 'Gaming', 'Audio', 'Accessories'
  ],
  'Vehicles': [
    'Cars', 'Motorcycles', 'Parts & Accessories', 'RVs & Campers', 'Boats'
  ],
  'Real Estate': [
    'Houses', 'Apartments', 'Land', 'Commercial', 'Parking'
  ],
  'Fashion': [
    'Men\'s Clothing', 'Women\'s Clothing', 'Shoes', 'Accessories', 'Jewelry'
  ],
  'Furniture': [
    'Sofas', 'Tables', 'Chairs', 'Beds', 'Storage', 'Outdoor'
  ],
  'Jobs': [
    'Full-time', 'Part-time', 'Contract', 'Freelance', 'Internship'
  ],
  'Gaming': [
    'Consoles', 'Video Games', 'Accessories', 'PC Gaming', 'Retro Gaming'
  ],
  'Photography': [
    'Cameras', 'Lenses', 'Lighting', 'Accessories', 'Studio Equipment'
  ],
  'Books': [
    'Fiction', 'Non-Fiction', 'Textbooks', 'Comics', 'Magazines'
  ],
  'Sports': [
    'Equipment', 'Apparel', 'Accessories', 'Outdoor Gear', 'Fitness'
  ],
  'Pets': [
    'Supplies', 'Accessories', 'Food', 'Toys', 'Services'
  ],
  'Others': [
    'Antiques', 'Art', 'Crafts', 'Music', 'Miscellaneous'
  ]
};

function CategorySelect({ category, subcategory, onChange }: CategorySelectProps) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-semibold mb-4">Select Category</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {categories.map((cat) => (
            <button
              key={cat.name}
              onClick={() => onChange(cat.name, '')}
              className={`p-4 rounded-lg border ${
                category === cat.name
                  ? 'border-primary-600 bg-primary-50 text-primary-700'
                  : 'border-gray-200 hover:border-primary-600 text-gray-700'
              } flex flex-col items-center space-y-2 transition-colors`}
            >
              <cat.icon className={`h-8 w-8 ${
                category === cat.name ? 'text-primary-600' : 'text-gray-600'
              }`} />
              <span className="text-sm font-medium">{cat.name}</span>
            </button>
          ))}
        </div>
      </div>

      {category && subcategories[category] && (
        <div>
          <h3 className="text-lg font-semibold mb-4">Select Subcategory</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {subcategories[category].map((sub) => (
              <button
                key={sub}
                onClick={() => onChange(category, sub)}
                className={`p-3 rounded-lg border ${
                  subcategory === sub
                    ? 'border-primary-600 bg-primary-50 text-primary-700'
                    : 'border-gray-200 hover:border-primary-600 text-gray-700'
                } transition-colors`}
              >
                <span className="text-sm font-medium">{sub}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CategorySelect;