import React from 'react';
import { Car, Shield, MapPin, Wrench } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const VehiclesPage = () => {
  const features = [
    {
      icon: Shield,
      title: 'Verified Sellers',
      description: 'All sellers are verified and trusted'
    },
    {
      icon: MapPin,
      title: 'Safe Test Drives',
      description: 'Meet at secure locations'
    },
    {
      icon: Wrench,
      title: 'Vehicle History',
      description: 'Detailed vehicle information'
    }
  ];

  return <CategoryPage category="Vehicles" icon={Car} features={features} />;
};

export default VehiclesPage;