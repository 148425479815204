import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { Profile } from '../types/profile';

const STORAGE_PREFIX = 'ynotpost_';

export const storage = {
  set<T>(key: string, value: T): void {
    try {
      localStorage.setItem(`${STORAGE_PREFIX}${key}`, JSON.stringify(value));
    } catch (error) {
      console.error('Storage error:', error);
      toast.error('Failed to save data');
    }
  },

  get<T>(key: string): T | null {
    try {
      const item = localStorage.getItem(`${STORAGE_PREFIX}${key}`);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error('Storage error:', error);
      return null;
    }
  },

  remove(key: string): void {
    localStorage.removeItem(`${STORAGE_PREFIX}${key}`);
  },

  clear(): void {
    Object.keys(localStorage)
      .filter(key => key.startsWith(STORAGE_PREFIX))
      .forEach(key => localStorage.removeItem(key));
  },

  // Profile management
  getProfile(userId: string): Profile | null {
    return this.get<Profile>(`profile_${userId}`);
  },

  updateProfile(userId: string, updates: Partial<Profile>): Profile {
    const currentProfile = this.getProfile(userId);
    if (!currentProfile) {
      throw new Error('Profile not found');
    }
    
    const updatedProfile = { ...currentProfile, ...updates };
    this.set(`profile_${userId}`, updatedProfile);
    return updatedProfile;
  },

  createDefaultProfile(userId: string): Profile {
    const defaultProfile: Profile = {
      id: userId,
      name: 'New User',
      bio: 'Tell others about yourself',
      location: '',
      joinDate: new Date().toISOString(),
      verificationLevel: 'Basic',
      responseTime: '< 24 hours',
      completedDeals: 0,
      rating: 0,
      reviews: 0,
      socialLinks: {},
      badges: [],
      stats: {
        itemsSold: 0,
        itemsBought: 0,
        totalEarnings: 0,
        completionRate: 100,
        repeatCustomers: 0,
        averageResponseTime: 0
      }
    };
    this.set(`profile_${userId}`, defaultProfile);
    return defaultProfile;
  }
};