import React, { useState } from 'react';
import { Check, X } from 'lucide-react';
import toast from 'react-hot-toast';

interface OfferActionsProps {
  chatId: string;
  lastOffer: {
    amount: number;
    by: 'buyer' | 'seller';
  };
  onAccept: () => Promise<void>;
  onDecline: () => void;
  onCounterOffer: (amount: number) => void;
}

const OfferActions: React.FC<OfferActionsProps> = ({
  lastOffer,
  onAccept,
  onDecline,
  onCounterOffer
}) => {
  const [showNegotiate, setShowNegotiate] = useState(false);
  const [offerAmount, setOfferAmount] = useState('');

  const handleMakeOffer = () => {
    const amount = Number(offerAmount);
    if (!amount || amount <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }

    onCounterOffer(amount);
    setShowNegotiate(false);
    setOfferAmount('');
  };

  return (
    <div className="p-4 border-t bg-gray-50">
      <div className="flex items-center justify-between">
        <div>
          <p className="font-medium text-gray-900">Current Offer</p>
          <p className="text-gray-600">${lastOffer.amount}</p>
        </div>
        <div className="flex gap-2">
          <button
            onClick={onAccept}
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2"
          >
            <Check className="w-5 h-5" />
            Accept
          </button>
          <button
            onClick={() => setShowNegotiate(true)}
            className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
          >
            Counter
          </button>
          <button
            onClick={onDecline}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
          >
            Decline
          </button>
        </div>
      </div>

      {showNegotiate && (
        <div className="mt-4 flex gap-2">
          <div className="relative flex-1">
            <span className="absolute left-3 top-2 text-gray-500">$</span>
            <input
              type="number"
              value={offerAmount}
              onChange={(e) => setOfferAmount(e.target.value)}
              className="w-full pl-8 pr-4 py-2 border rounded-lg"
              placeholder="Enter counter-offer amount"
            />
          </div>
          <button
            onClick={handleMakeOffer}
            className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
          >
            Send
          </button>
          <button
            onClick={() => setShowNegotiate(false)}
            className="px-4 py-2 border rounded-lg hover:bg-gray-100"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default OfferActions;