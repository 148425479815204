import React from 'react';
import { Wrench, Shield, Zap, Package } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const ToolsPage = () => {
  const features = [
    {
      icon: Zap,
      title: 'Power Testing',
      description: 'Test all power tools'
    },
    {
      icon: Shield,
      title: 'Safety Certified',
      description: 'All tools safety checked'
    },
    {
      icon: Package,
      title: 'Original Parts',
      description: 'Genuine components'
    }
  ];

  return <CategoryPage category="Tools" icon={Wrench} features={features} />;
};

export default ToolsPage;