import React, { useState } from 'react';
import { useListingStore } from '../store/listingStore';
import PostCard from './PostCard';
import PostDetail from './PostDetail';

interface CategoryPageProps {
  category: string;
  icon: React.FC<{ className?: string }>;
  features: {
    icon: React.FC<{ className?: string }>;
    title: string;
    description: string;
  }[];
}

const CategoryPage: React.FC<CategoryPageProps> = ({ category, icon: Icon, features }) => {
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const getListingsByCategory = useListingStore(state => state.getListingsByCategory);
  const listings = getListingsByCategory(category);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-3">
          <Icon className="w-8 h-8 text-primary-600" />
          <h1 className="text-2xl font-bold text-gray-900">{category}</h1>
        </div>
        <span className="text-sm text-gray-500">
          {listings.length} {listings.length === 1 ? 'listing' : 'listings'}
        </span>
      </div>

      {/* Category Features */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {features.map((feature, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
            <feature.icon className="w-6 h-6 text-primary-600 mb-2" />
            <h3 className="font-medium text-gray-900 mb-1">{feature.title}</h3>
            <p className="text-sm text-gray-500">{feature.description}</p>
          </div>
        ))}
      </div>

      {/* Listings Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
        {listings.map((listing) => (
          <PostCard
            key={listing.id}
            post={listing}
            onPostClick={() => setSelectedPost(listing)}
            showHeart={true}
          />
        ))}
      </div>

      {listings.length === 0 && (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <Icon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            No items listed in {category}
          </h3>
          <p className="text-gray-500">Be the first to list in this category</p>
        </div>
      )}

      {selectedPost && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto flex items-center justify-center">
          <div className="relative w-full max-w-4xl mx-auto my-8">
            <PostDetail
              post={selectedPost}
              onClose={() => setSelectedPost(null)}
              onProfileClick={() => {}}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryPage;