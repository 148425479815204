import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Camera, Upload, Star, MessageCircle, ThumbsUp, User, Package, ShoppingBag, Edit2, Trash2, DollarSign } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { useReviewStore } from '../store/reviewStore';
import { useListingStore } from '../store/listingStore';
import { useChatStore } from '../store/chatStore';
import PostForm from '../components/CreatePost/PostForm';
import ReviewsSection from '../components/Profile/ReviewsSection';
import ProfileEditModal from '../components/Profile/ProfileEditModal';
import toast from 'react-hot-toast';

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuthStore();
  const { getAverageRating, getReviewCount, getReviewsForUser } = useReviewStore();
  const { getListingsByUser, getPurchasesByUser, removeListing, updateListing, markAsSold } = useListingStore();
  const { initiateChat } = useChatStore();
  
  const [isHoveringBanner, setIsHoveringBanner] = useState(false);
  const [isHoveringPhoto, setIsHoveringPhoto] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPostForm, setShowPostForm] = useState(false);
  const [editingListing, setEditingListing] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<'listings' | 'purchases' | 'reviews'>('listings');
  const [listingsFilter, setListingsFilter] = useState<'all' | 'active' | 'sold'>('all');
  const [reviewsFilter, setReviewsFilter] = useState<'received' | 'given'>('received');
  const [selectedListing, setSelectedListing] = useState<any>(null);

  const [profile, setProfile] = useState({
    name: user?.name || 'User',
    location: 'San Francisco, CA',
    joinDate: new Date().toISOString(),
    rating: 0,
    reviews: 0,
    banner_url: undefined,
    avatar_url: user?.avatar || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    socialLinks: {},
    stats: {
      receivedReviews: [],
      givenReviews: []
    }
  });

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
      toast.error('Please sign in to view profile');
      return;
    }

    if (user) {
      const userListings = getListingsByUser(user.id);
      const userPurchases = getPurchasesByUser(user.id);
      const rating = getAverageRating(user.id);
      const reviewCount = getReviewCount(user.id);
      const receivedReviews = getReviewsForUser(user.id, 'received');
      const givenReviews = getReviewsForUser(user.id, 'given');

      setProfile(prev => ({
        ...prev,
        name: user.name,
        rating,
        reviews: reviewCount,
        stats: {
          ...prev.stats,
          totalListings: userListings.length,
          totalPurchases: userPurchases.length,
          soldListings: userListings.filter(l => l.sold).length,
          receivedReviews,
          givenReviews
        }
      }));
    }
  }, [isAuthenticated, user, navigate, getListingsByUser, getPurchasesByUser, getAverageRating, getReviewCount, getReviewsForUser]);

  const handlePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const imageUrl = URL.createObjectURL(file);
        setProfile(prev => ({ ...prev, avatar_url: imageUrl }));
        toast.success('Profile photo updated!');
      } catch (error) {
        toast.error('Failed to update profile photo');
      }
    }
  };

  const handleBannerUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const imageUrl = URL.createObjectURL(file);
        setProfile(prev => ({ ...prev, banner_url: imageUrl }));
        toast.success('Banner updated!');
      } catch (error) {
        toast.error('Failed to update banner');
      }
    }
  };

  const handleProfileUpdate = (updatedProfile: any) => {
    setProfile(prev => ({
      ...prev,
      ...updatedProfile
    }));
    setShowSettings(false);
    toast.success('Profile updated successfully!');
  };

  const handleDeleteListing = (listingId: string) => {
    if (window.confirm('Are you sure you want to delete this listing?')) {
      removeListing(listingId);
      toast.success('Listing deleted successfully');
    }
  };

  const handleMarkAsSold = async (listingId: string, buyerId: string) => {
    try {
      await markAsSold(listingId, buyerId);
      toast.success('Listing marked as sold');
      
      // Initiate chat with buyer
      initiateChat({
        listingId,
        sellerId: user?.id || '',
        buyerId,
        type: 'direct',
        amount: selectedListing.price
      });
    } catch (error) {
      toast.error('Failed to mark listing as sold');
    }
  };

  const handleContactBuyer = (listing: any) => {
    if (!listing.buyerId) return;

    initiateChat({
      listingId: listing.id,
      sellerId: user?.id || '',
      buyerId: listing.buyerId,
      type: 'direct',
      amount: listing.price
    });
    toast.success('Chat opened with buyer');
  };

  const getFilteredListings = () => {
    if (!user) return [];
    const listings = getListingsByUser(user.id);
    switch (listingsFilter) {
      case 'sold':
        return listings.filter(l => l.sold);
      case 'active':
        return listings.filter(l => !l.sold);
      default:
        return listings;
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="relative mb-24">
          <div 
            className="relative h-48 md:h-64 overflow-hidden bg-primary-600"
            onMouseEnter={() => setIsHoveringBanner(true)}
            onMouseLeave={() => setIsHoveringBanner(false)}
          >
            {profile.banner_url && (
              <img
                src={profile.banner_url}
                alt="Profile Banner"
                className="w-full h-full object-cover"
              />
            )}
            <label className={`absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity cursor-pointer ${
              isHoveringBanner ? 'opacity-100' : 'opacity-0'
            }`}>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleBannerUpload}
              />
              <Upload className="w-8 h-8 text-white" />
            </label>
          </div>

          <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-16">
            <div 
              className="relative group"
              onMouseEnter={() => setIsHoveringPhoto(true)}
              onMouseLeave={() => setIsHoveringPhoto(false)}
            >
              <img
                src={profile.avatar_url}
                alt={profile.name}
                className="w-32 h-32 rounded-full border-4 border-white object-cover shadow-lg"
              />
              <label className={`absolute inset-0 rounded-full bg-black bg-opacity-50 flex items-center justify-center transition-opacity cursor-pointer ${
                isHoveringPhoto ? 'opacity-100' : 'opacity-0'
              }`}>
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handlePhotoUpload}
                />
                <Camera className="w-6 h-6 text-white" />
              </label>
            </div>
          </div>
        </div>

        <div className="text-center mb-8 px-6">
          <h1 className="text-2xl font-bold text-gray-900">{profile.name}</h1>
          <div className="flex items-center justify-center gap-2 text-gray-600 mt-2">
            <span>{profile.location}</span>
            <span className="text-gray-400">·</span>
            <span>Member since {new Date(profile.joinDate).toLocaleDateString()}</span>
          </div>
          <div className="flex items-center justify-center gap-2 mt-2">
            <div className="flex items-center">
              <Star className="w-5 h-5 text-yellow-400 fill-current" />
              <span className="ml-1 font-semibold text-lg">{profile.rating.toFixed(1)}</span>
            </div>
            <span className="text-gray-600">({profile.reviews} reviews)</span>
          </div>

          <button 
            onClick={() => setShowSettings(true)}
            className="mt-4 px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors font-medium"
          >
            Profile
          </button>
        </div>

        <div className="border-b">
          <div className="max-w-7xl mx-auto px-6">
            <div className="flex gap-4 justify-center">
              <button
                onClick={() => setActiveTab('listings')}
                className={`py-4 px-2 relative flex items-center gap-2 ${
                  activeTab === 'listings'
                    ? 'text-primary-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                <Package className="w-5 h-5" />
                Listings
                {activeTab === 'listings' && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary-600" />
                )}
              </button>
              <button
                onClick={() => setActiveTab('purchases')}
                className={`py-4 px-2 relative flex items-center gap-2 ${
                  activeTab === 'purchases'
                    ? 'text-primary-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                <ShoppingBag className="w-5 h-5" />
                Purchases
                {activeTab === 'purchases' && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary-600" />
                )}
              </button>
              <button
                onClick={() => setActiveTab('reviews')}
                className={`py-4 px-2 relative flex items-center gap-2 ${
                  activeTab === 'reviews'
                    ? 'text-primary-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                <Star className="w-5 h-5" />
                Reviews
                {activeTab === 'reviews' && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary-600" />
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="px-6 py-8">
          {activeTab === 'listings' && (
            <>
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6 gap-4 sm:gap-0">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                  <h2 className="text-2xl font-bold text-gray-900">My Listings</h2>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setListingsFilter('all')}
                      className={`px-4 py-2 rounded-lg ${
                        listingsFilter === 'all'
                          ? 'bg-primary-600 text-white'
                          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      All
                    </button>
                    <button
                      onClick={() => setListingsFilter('active')}
                      className={`px-4 py-2 rounded-lg ${
                        listingsFilter === 'active'
                          ? 'bg-primary-600 text-white'
                          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      Active
                    </button>
                    <button
                      onClick={() => setListingsFilter('sold')}
                      className={`px-4 py-2 rounded-lg ${
                        listingsFilter === 'sold'
                          ? 'bg-primary-600 text-white'
                          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      Sold
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => setShowPostForm(true)}
                  className="w-full sm:w-auto px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
                >
                  Create Listing
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {getFilteredListings().length > 0 ? (
                  getFilteredListings().map((listing) => (
                    <div key={listing.id} className="bg-white rounded-lg shadow-sm overflow-hidden group relative">
                      <img
                        src={listing.images[0]}
                        alt={listing.title}
                        className="w-full h-48 object-cover"
                      />
                      {listing.sold && (
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                          <span className="px-3 py-1 bg-green-500 text-white rounded-full text-sm font-medium">
                            Sold
                          </span>
                        </div>
                      )}
                      <div className="p-4">
                        <h3 className="font-medium text-gray-900">{listing.title}</h3>
                        <div className="flex items-center justify-between mt-2">
                          <div className="flex items-center gap-1 text-primary-600 font-semibold">
                            <DollarSign className="w-4 h-4" />
                            {listing.price}
                          </div>
                          {listing.sold && (
                            <button
                              onClick={() => handleContactBuyer(listing)}
                              className="flex items-center gap-1 text-primary-600 hover:text-primary-700"
                            >
                              <MessageCircle className="w-4 h-4" />
                              Contact Buyer
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2">
                        <button
                          onClick={() => setEditingListing(listing)}
                          className="p-2 bg-white rounded-full shadow-sm hover:bg-gray-100"
                        >
                          <Edit2 className="w-4 h-4 text-gray-600" />
                        </button>
                        <button
                          onClick={() => handleDeleteListing(listing.id)}
                          className="p-2 bg-white rounded-full shadow-sm hover:bg-gray-100"
                        >
                          <Trash2 className="w-4 h-4 text-gray-600" />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-full text-center py-12 bg-gray-50 rounded-lg">
                    <Package className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">No listings found</h3>
                    <p className="text-gray-500">
                      {listingsFilter === 'all'
                        ? "You haven't created any listings yet"
                        : listingsFilter === 'active'
                        ? 'No active listings'
                        : 'No sold items yet'}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}

          {activeTab === 'purchases' && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">My Purchases</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {profile.stats.totalPurchases > 0 ? (
                  getPurchasesByUser(user?.id || '').map((purchase) => (
                    <div key={purchase.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
                      <img
                        src={purchase.images[0]}
                        alt={purchase.title}
                        className="w-full h-48 object-cover"
                      />
                      <div className="p-4">
                        <h3 className="font-medium text-gray-900">{purchase.title}</h3>
                        <p className="text-gray-600">${purchase.price}</p>
                        <div className="mt-2 flex items-center gap-2">
                          <img
                            src={purchase.seller.avatar}
                            alt={purchase.seller.name}
                            className="w-6 h-6 rounded-full"
                          />
                          <span className="text-sm text-gray-500">
                            Bought from {purchase.seller.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-full text-center py-12 bg-gray-50 rounded-lg">
                    <ShoppingBag className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">No purchases yet</h3>
                    <p className="text-gray-500">Items you buy will appear here</p>
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === 'reviews' && (
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold text-gray-900">Reviews</h2>
                <div className="flex gap-2">
                  <button
                    onClick={() => setReviewsFilter('received')}
                    className={`px-4 py-2 rounded-lg ${
                      reviewsFilter === 'received'
                        ? 'bg-primary-600 text-white'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <MessageCircle className="w-4 h-4" />
                      Received
                    </div>
                  </button>
                  <button
                    onClick={() => setReviewsFilter('given')}
                    className={`px-4 py-2 rounded-lg ${
                      reviewsFilter === 'given'
                        ? 'bg-primary-600 text-white'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <ThumbsUp className="w-4 h-4" />
                      Given
                    </div>
                  </button>
                </div>
              </div>
              <ReviewsSection 
                reviews={reviewsFilter === 'received' ? profile.stats.receivedReviews : profile.stats.givenReviews}
                type={reviewsFilter}
              />
            </div>
          )}
        </div>
      </div>

      {showSettings && (
        <ProfileEditModal
          profile={profile}
          onClose={() => setShowSettings(false)}
          onSave={handleProfileUpdate}
        />
      )}

      {(showPostForm || editingListing) && (
        <PostForm
          listing={editingListing}
          onClose={() => {
            setShowPostForm(false);
            setEditingListing(null);
          }}
          onPostCreated={(updatedListing) => {
            if (editingListing) {
              updateListing(editingListing.id, updatedListing);
              toast.success('Listing updated successfully');
            } else {
              toast.success('Listing created successfully!');
            }
            setShowPostForm(false);
            setEditingListing(null);
          }}
        />
      )}
    </div>
  );
};

export default ProfilePage;