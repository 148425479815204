import React, { useState } from 'react';
import { X, Eye, EyeOff, Shield, ArrowRight, User } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { testUsers } from '../../utils/testUsers';
import toast from 'react-hot-toast';

interface AuthModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ onClose, onSuccess }) => {
  const { signIn, signUp, adminSignIn } = useAuthStore();
  const [isLogin, setIsLogin] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
    name: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isAdmin) {
        await adminSignIn(form.email, form.password);
        toast.success('Welcome to Admin Dashboard!');
        window.location.href = '/admin';
        return;
      }

      if (isLogin) {
        await signIn(form.email, form.password);
        toast.success('Welcome back!');
      } else {
        await signUp(form.email, form.password, form.name);
        toast.success('Welcome to YnotPost!');
      }
      onSuccess();
      onClose();
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Authentication failed');
    }
  };

  const handleQuickLogin = async (type: 'buyer' | 'seller') => {
    const user = type === 'buyer' ? testUsers.buyer : testUsers.seller;
    try {
      await signIn(user.email, user.password);
      toast.success(`Welcome back, ${user.name}!`);
      onSuccess();
      onClose();
    } catch (error) {
      toast.error('Login failed');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-semibold">
            {isAdmin ? 'Admin Login' : isLogin ? 'Welcome Back' : 'Join YnotPost'}
          </h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          {/* Quick Login Buttons */}
          {isLogin && !isAdmin && (
            <div className="grid grid-cols-2 gap-4 mb-6">
              <button
                type="button"
                onClick={() => handleQuickLogin('buyer')}
                className="flex items-center justify-center gap-2 px-4 py-2 bg-primary-50 text-primary-600 rounded-lg hover:bg-primary-100"
              >
                <User className="w-5 h-5" />
                Login as Buyer
              </button>
              <button
                type="button"
                onClick={() => handleQuickLogin('seller')}
                className="flex items-center justify-center gap-2 px-4 py-2 bg-primary-50 text-primary-600 rounded-lg hover:bg-primary-100"
              >
                <User className="w-5 h-5" />
                Login as Seller
              </button>
            </div>
          )}

          {!isLogin && !isAdmin && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name
              </label>
              <input
                type="text"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                required
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              value={form.email}
              onChange={e => setForm({ ...form, email: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                value={form.password}
                onChange={e => setForm({ ...form, password: e.target.value })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
              >
                {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 font-medium"
          >
            {isAdmin ? 'Sign in to Dashboard' : isLogin ? 'Sign In' : 'Create Account'}
          </button>

          {!isAdmin && (
            <div className="flex items-center justify-center space-x-2 text-sm">
              <span className="text-gray-600">
                {isLogin ? "Don't have an account?" : 'Already have an account?'}
              </span>
              <button
                type="button"
                onClick={() => {
                  setIsLogin(!isLogin);
                  setForm({ email: '', password: '', name: '' });
                }}
                className="text-primary-600 hover:text-primary-700 font-medium"
              >
                {isLogin ? 'Sign Up' : 'Sign In'}
              </button>
            </div>
          )}

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">or</span>
            </div>
          </div>

          <button
            type="button"
            onClick={() => {
              setIsAdmin(!isAdmin);
              setIsLogin(true);
              setForm({ email: '', password: '', name: '' });
            }}
            className="w-full py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 font-medium flex items-center justify-center gap-2"
          >
            {isAdmin ? (
              <>
                <ArrowRight className="w-5 h-5" />
                Back to User Login
              </>
            ) : (
              <>
                <Shield className="w-5 h-5" />
                Admin Dashboard
              </>
            )}
          </button>

          <div className="flex items-center text-sm text-gray-500 mt-4">
            <Shield className="w-4 h-4 mr-2" />
            <span>Your data is securely encrypted</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthModal;