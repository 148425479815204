import React from 'react';
import { 
  MessageCircle, ShoppingBag, CircleDot, Send, Share2, Target, 
  Zap, Globe, Box, Sparkles, Hash, AtSign, Camera, Bookmark,
  Link2, Hexagon, Layers, Infinity, Activity, Compass
} from 'lucide-react';

interface LogoProps {
  variant?: 
    | 'default' | 'minimal' | 'modern' | 'playful' | 'geometric' 
    | 'dynamic' | 'tech' | 'global' | 'bold' | 'elegant'
    | 'social' | 'gram' | 'thread' | 'bookmark' | 'link'
    | 'hex' | 'stack' | 'infinite' | 'pulse' | 'discover';
  size?: 'sm' | 'md' | 'lg';
}

const Logo: React.FC<LogoProps> = ({ variant = 'default', size = 'md' }) => {
  const sizeClasses = {
    sm: 'h-6',
    md: 'h-8',
    lg: 'h-10'
  };

  // Keep all previous render functions...
  const renderDefault = () => (
    <div className="flex items-center gap-2">
      <div className="bg-primary-600 text-white p-2 rounded-lg">
        <MessageCircle className="w-6 h-6" />
      </div>
      <span className="font-bold text-xl tracking-tight">
        <span className="text-primary-600">Ynot</span>
        <span className="text-gray-900">Post</span>
      </span>
    </div>
  );

  const renderMinimal = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <CircleDot className="w-8 h-8 text-primary-600" />
        <span className="absolute inset-0 flex items-center justify-center font-bold text-sm text-white">
          Y
        </span>
      </div>
      <span className="font-bold text-xl">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderModern = () => (
    <div className="flex items-center gap-2">
      <div className="bg-gradient-to-br from-primary-500 to-primary-700 text-white p-2 rounded-xl transform rotate-12">
        <Send className="w-6 h-6 -rotate-12" />
      </div>
      <span className="font-extrabold text-xl uppercase tracking-wider">
        <span className="text-primary-600">Ynot</span>
        <span className="text-gray-900">Post</span>
      </span>
    </div>
  );

  const renderPlayful = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="bg-primary-600 w-8 h-8 rounded-full flex items-center justify-center transform -rotate-12">
          <ShoppingBag className="w-5 h-5 text-white" />
        </div>
        <div className="absolute -top-1 -right-1 w-4 h-4 bg-white rounded-full flex items-center justify-center">
          <span className="text-xs font-bold text-primary-600">Y</span>
        </div>
      </div>
      <span className="font-bold text-xl" style={{ fontFamily: 'system-ui' }}>
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  // Add new social media inspired renders...
  const renderSocial = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="bg-gradient-to-tr from-primary-600 via-primary-500 to-primary-400 w-8 h-8 rounded-lg">
          <Hash className="w-8 h-8 text-white p-1.5" />
        </div>
      </div>
      <span className="font-bold text-xl">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderGram = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="bg-gradient-to-br from-primary-600 via-pink-500 to-yellow-500 w-8 h-8 rounded-lg p-1.5">
          <Camera className="w-5 h-5 text-white" />
        </div>
      </div>
      <span className="font-light text-xl tracking-wide">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderThread = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <AtSign className="w-8 h-8 text-primary-600" />
      </div>
      <span className="font-serif text-xl tracking-tight">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderBookmark = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="bg-primary-600 w-8 h-8 rounded-lg flex items-center justify-center">
          <Bookmark className="w-5 h-5 text-white" />
        </div>
      </div>
      <span className="font-bold text-xl tracking-tight">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderLink = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="w-8 h-8 rounded-full bg-gradient-to-r from-primary-600 to-primary-400 flex items-center justify-center">
          <Link2 className="w-5 h-5 text-white transform rotate-45" />
        </div>
      </div>
      <span className="font-bold text-xl">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderHex = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <Hexagon className="w-8 h-8 text-primary-600 fill-current" />
        <span className="absolute inset-0 flex items-center justify-center font-bold text-sm text-white">Y</span>
      </div>
      <span className="font-bold text-xl tracking-tight">
        <span className="text-primary-600">YNOT</span>
        <span className="text-gray-900">POST</span>
      </span>
    </div>
  );

  const renderStack = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <Layers className="w-8 h-8 text-primary-600" />
      </div>
      <span className="font-bold text-xl tracking-tight">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderInfinite = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="bg-primary-600 w-8 h-8 rounded-lg flex items-center justify-center">
          <Infinity className="w-5 h-5 text-white" />
        </div>
      </div>
      <span className="font-extrabold text-xl tracking-tight">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderPulse = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="bg-gradient-to-r from-primary-600 to-primary-400 w-8 h-8 rounded-full flex items-center justify-center">
          <Activity className="w-5 h-5 text-white" />
        </div>
      </div>
      <span className="font-bold text-xl tracking-tight">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  const renderDiscover = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="bg-primary-600 w-8 h-8 rounded-lg flex items-center justify-center transform rotate-45">
          <Compass className="w-5 h-5 text-white -rotate-45" />
        </div>
      </div>
      <span className="font-bold text-xl tracking-tight">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
      </span>
    </div>
  );

  // Keep previous variant renders...
  const renderGeometric = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="w-8 h-8 bg-primary-600 transform rotate-45" />
        <Target className="w-5 h-5 text-white absolute inset-0 m-auto" />
      </div>
      <span className="font-bold text-xl tracking-tight">
        <span className="text-primary-600">YNOT</span>
        <span className="text-gray-900">POST</span>
      </span>
    </div>
  );

  const renderDynamic = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="w-8 h-8 bg-gradient-to-tr from-primary-600 to-primary-400 rounded-tr-2xl rounded-bl-2xl" />
        <Share2 className="w-5 h-5 text-white absolute inset-0 m-auto" />
      </div>
      <span className="font-bold text-xl italic">
        <span className="text-primary-600">Ynot</span>
        <span className="text-gray-900">Post</span>
      </span>
    </div>
  );

  const renderTech = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="w-8 h-8 border-2 border-primary-600 rounded-lg flex items-center justify-center">
          <Zap className="w-5 h-5 text-primary-600" />
        </div>
      </div>
      <span className="font-mono font-bold text-xl">
        <span className="text-primary-600">&lt;ynot</span>
        <span className="text-gray-900">post/&gt;</span>
      </span>
    </div>
  );

  const renderGlobal = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <Globe className="w-8 h-8 text-primary-600" />
        <span className="absolute inset-0 flex items-center justify-center font-bold text-xs text-white">Y</span>
      </div>
      <span className="font-bold text-xl">
        <span className="text-primary-600">ynot</span>
        <span className="text-gray-900">post</span>
        <span className="text-xs text-primary-400">.com</span>
      </span>
    </div>
  );

  const renderBold = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <Box className="w-8 h-8 text-primary-600 transform -rotate-6" />
        <span className="absolute inset-0 flex items-center justify-center font-black text-lg text-white">Y</span>
      </div>
      <span className="font-black text-xl tracking-tight">
        <span className="text-primary-600">YNOT</span>
        <span className="text-gray-900">POST</span>
      </span>
    </div>
  );

  const renderElegant = () => (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="w-8 h-8 bg-primary-600 rounded-full flex items-center justify-center overflow-hidden">
          <Sparkles className="w-5 h-5 text-white" />
        </div>
      </div>
      <span className="font-serif text-xl">
        <span className="text-primary-600">Ynot</span>
        <span className="text-gray-900">Post</span>
      </span>
    </div>
  );

  const variants = {
    default: renderDefault,
    minimal: renderMinimal,
    modern: renderModern,
    playful: renderPlayful,
    geometric: renderGeometric,
    dynamic: renderDynamic,
    tech: renderTech,
    global: renderGlobal,
    bold: renderBold,
    elegant: renderElegant,
    social: renderSocial,
    gram: renderGram,
    thread: renderThread,
    bookmark: renderBookmark,
    link: renderLink,
    hex: renderHex,
    stack: renderStack,
    infinite: renderInfinite,
    pulse: renderPulse,
    discover: renderDiscover
  };

  return (
    <div className={sizeClasses[size]}>
      {variants[variant]?.()}
    </div>
  );
};

export default Logo;