import { v4 as uuidv4 } from 'uuid';

export const testUsers = {
  buyer: {
    id: uuidv4(),
    name: 'Alice Johnson',
    email: 'buyer@test.com',
    password: 'buyer123',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    role: 'user',
    profile: {
      bio: 'Avid collector and enthusiast',
      location: 'San Francisco, CA',
      joinDate: '2024-01-15',
      verificationLevel: 'Verified',
      responseTime: '< 1 hour',
      completedDeals: 12,
      rating: 4.8,
      reviews: 10,
      socialLinks: {
        instagram: 'alicejbuyer',
        twitter: 'alicejbuyer'
      },
      stats: {
        itemsSold: 0,
        itemsBought: 12,
        totalEarnings: 0,
        completionRate: 100,
        repeatCustomers: 0,
        averageResponseTime: 15
      }
    }
  },
  seller: {
    id: uuidv4(),
    name: 'John Smith',
    email: 'seller@test.com',
    password: 'seller123',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    role: 'user',
    profile: {
      bio: 'Professional seller with quality items',
      location: 'Los Angeles, CA',
      joinDate: '2023-12-01',
      verificationLevel: 'Verified Plus',
      responseTime: '< 30 mins',
      completedDeals: 45,
      rating: 4.9,
      reviews: 40,
      socialLinks: {
        instagram: 'johnsseller',
        twitter: 'johnsseller'
      },
      stats: {
        itemsSold: 45,
        itemsBought: 5,
        totalEarnings: 15000,
        completionRate: 98,
        repeatCustomers: 15,
        averageResponseTime: 10
      }
    }
  }
};