import React, { useState } from 'react';
import { 
  Save, Settings, Mail, Shield, Bell, DollarSign, 
  Lock, AlertTriangle, CheckCircle 
} from 'lucide-react';
import { useAdminStore } from '../../store/adminStore';
import toast from 'react-hot-toast';

const SettingsPanel: React.FC = () => {
  const { settings, updateSettings } = useAdminStore();
  const [form, setForm] = useState(settings);
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    try {
      await updateSettings(form);
      toast.success('Settings updated successfully');
    } catch (error) {
      toast.error('Failed to update settings');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">System Settings</h2>
        <button
          onClick={handleSubmit}
          disabled={saving}
          className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 flex items-center gap-2"
        >
          <Save className="w-5 h-5" />
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* General Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
            <Settings className="w-5 h-5 text-gray-500" />
            General Settings
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Site Name
              </label>
              <input
                type="text"
                value={form.general.siteName}
                onChange={(e) => setForm({
                  ...form,
                  general: { ...form.general, siteName: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Site Description
              </label>
              <input
                type="text"
                value={form.general.siteDescription}
                onChange={(e) => setForm({
                  ...form,
                  general: { ...form.general, siteDescription: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Contact Email
              </label>
              <input
                type="email"
                value={form.general.contactEmail}
                onChange={(e) => setForm({
                  ...form,
                  general: { ...form.general, contactEmail: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Support Email
              </label>
              <input
                type="email"
                value={form.general.supportEmail}
                onChange={(e) => setForm({
                  ...form,
                  general: { ...form.general, supportEmail: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Moderation Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
            <Shield className="w-5 h-5 text-gray-500" />
            Moderation Settings
          </h3>
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                id="requireListingApproval"
                checked={form.moderation.requireListingApproval}
                onChange={(e) => setForm({
                  ...form,
                  moderation: { ...form.moderation, requireListingApproval: e.target.checked }
                })}
                className="h-4 w-4 text-primary-600 rounded"
              />
              <label htmlFor="requireListingApproval" className="text-sm text-gray-700">
                Require approval for new listings
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                id="autoBlockReportedUsers"
                checked={form.moderation.autoBlockReportedUsers}
                onChange={(e) => setForm({
                  ...form,
                  moderation: { ...form.moderation, autoBlockReportedUsers: e.target.checked }
                })}
                className="h-4 w-4 text-primary-600 rounded"
              />
              <label htmlFor="autoBlockReportedUsers" className="text-sm text-gray-700">
                Automatically block users after multiple reports
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Reports before auto-block
              </label>
              <input
                type="number"
                value={form.moderation.maxReportsBeforeBlock}
                onChange={(e) => setForm({
                  ...form,
                  moderation: { ...form.moderation, maxReportsBeforeBlock: parseInt(e.target.value) }
                })}
                min="1"
                className="w-32 px-4 py-2 border rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Fee Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
            <DollarSign className="w-5 h-5 text-gray-500" />
            Fee Settings
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Listing Fee ($)
              </label>
              <input
                type="number"
                value={form.fees.listingFee}
                onChange={(e) => setForm({
                  ...form,
                  fees: { ...form.fees, listingFee: parseFloat(e.target.value) }
                })}
                min="0"
                step="0.01"
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Transaction Fee (%)
              </label>
              <input
                type="number"
                value={form.fees.transactionFee}
                onChange={(e) => setForm({
                  ...form,
                  fees: { ...form.fees, transactionFee: parseFloat(e.target.value) }
                })}
                min="0"
                max="100"
                step="0.1"
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Featured Listing Fee ($)
              </label>
              <input
                type="number"
                value={form.fees.featuredListingFee}
                onChange={(e) => setForm({
                  ...form,
                  fees: { ...form.fees, featuredListingFee: parseFloat(e.target.value) }
                })}
                min="0"
                step="0.01"
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Notification Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
            <Bell className="w-5 h-5 text-gray-500" />
            Notification Settings
          </h3>
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                id="emailNotifications"
                checked={form.notifications.emailNotifications}
                onChange={(e) => setForm({
                  ...form,
                  notifications: { ...form.notifications, emailNotifications: e.target.checked }
                })}
                className="h-4 w-4 text-primary-600 rounded"
              />
              <label htmlFor="emailNotifications" className="text-sm text-gray-700">
                Enable email notifications
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                id="pushNotifications"
                checked={form.notifications.pushNotifications}
                onChange={(e) => setForm({
                  ...form,
                  notifications: { ...form.notifications, pushNotifications: e.target.checked }
                })}
                className="h-4 w-4 text-primary-600 rounded"
              />
              <label htmlFor="pushNotifications" className="text-sm text-gray-700">
                Enable push notifications
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                id="adminAlerts"
                checked={form.notifications.adminAlerts}
                onChange={(e) => setForm({
                  ...form,
                  notifications: { ...form.notifications, adminAlerts: e.target.checked }
                })}
                className="h-4 w-4 text-primary-600 rounded"
              />
              <label htmlFor="adminAlerts" className="text-sm text-gray-700">
                Receive admin alerts
              </label>
            </div>
          </div>
        </div>

        {/* Limit Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
            <Lock className="w-5 h-5 text-gray-500" />
            Limit Settings
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Max Images per Listing
              </label>
              <input
                type="number"
                value={form.limits.maxImagesPerListing}
                onChange={(e) => setForm({
                  ...form,
                  limits: { ...form.limits, maxImagesPerListing: parseInt(e.target.value) }
                })}
                min="1"
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Max Listings per User
              </label>
              <input
                type="number"
                value={form.limits.maxListingsPerUser}
                onChange={(e) => setForm({
                  ...form,
                  limits: { ...form.limits, maxListingsPerUser: parseInt(e.target.value) }
                })}
                min="1"
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Max Messages per Day
              </label>
              <input
                type="number"
                value={form.limits.maxMessagesPerDay}
                onChange={(e) => setForm({
                  ...form,
                  limits: { ...form.limits, maxMessagesPerDay: parseInt(e.target.value) }
                })}
                min="1"
                className="w-full px-4 py-2 border rounded-lg"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingsPanel;