import React from 'react';
import { Smartphone, Shield, Zap, CheckCircle } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const ElectronicsPage = () => {
  const features = [
    {
      icon: Shield,
      title: 'Buyer Protection',
      description: 'Secure transactions and verified sellers'
    },
    {
      icon: Zap,
      title: 'Testing Available',
      description: 'Test before you buy'
    },
    {
      icon: CheckCircle,
      title: 'Verified Items',
      description: 'All electronics verified working'
    }
  ];

  return <CategoryPage category="Electronics" icon={Smartphone} features={features} />;
};

export default ElectronicsPage;