import React from 'react';
import { Music, Shield, Headphones, Star } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const MusicPage = () => {
  const features = [
    {
      icon: Headphones,
      title: 'Equipment Testing',
      description: 'Test before purchase'
    },
    {
      icon: Shield,
      title: 'Authentication',
      description: 'Expert verification'
    },
    {
      icon: Star,
      title: 'Expert Verified',
      description: 'Professional inspection'
    }
  ];

  return <CategoryPage category="Music" icon={Music} features={features} />;
};

export default MusicPage;