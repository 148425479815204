import React, { useState, useRef, useEffect } from 'react';
import { 
  Car, Sofa, Smartphone, Shirt, Gamepad, Building, Home as HomeIcon, 
  Bike, Wrench, Baby, Music, Watch, Footprints, Gift, ChevronLeft, ChevronRight
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const categories = [
  { id: 1, name: 'Vehicles', icon: Car, path: '/categories/vehicles' },
  { id: 2, name: 'Furniture', icon: Sofa, path: '/categories/furniture' },
  { id: 3, name: 'Electronics', icon: Smartphone, path: '/categories/electronics' },
  { id: 4, name: 'Clothing', icon: Shirt, path: '/categories/clothing' },
  { id: 5, name: 'Gaming', icon: Gamepad, path: '/categories/gaming' },
  { id: 6, name: 'Real Estate', icon: Building, path: '/categories/real-estate' },
  { id: 7, name: 'Home & Garden', icon: HomeIcon, path: '/categories/home-garden' },
  { id: 8, name: 'Sports', icon: Bike, path: '/categories/sports' },
  { id: 9, name: 'Tools', icon: Wrench, path: '/categories/tools' },
  { id: 10, name: 'Baby & Kids', icon: Baby, path: '/categories/baby-kids' },
  { id: 11, name: 'Music', icon: Music, path: '/categories/music' },
  { id: 12, name: 'Watches', icon: Watch, path: '/categories/watches' },
  { id: 13, name: 'Shoes', icon: Footprints, path: '/categories/shoes' },
  { id: 14, name: 'Collectibles', icon: Gift, path: '/categories/collectibles' }
];

interface CategoryBarProps {
  onHomeClick: () => void;
  selectedCategory?: string | null;
  onCategorySelect: (category: string | null) => void;
}

const CategoryBar: React.FC<CategoryBarProps> = ({ 
  onHomeClick, 
  selectedCategory,
  onCategorySelect
}) => {
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 10);
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
      return () => scrollElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.clientWidth * 0.8;
      const targetScroll = scrollRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
      
      scrollRef.current.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.touches[0].clientX);
    
    if (scrollRef.current) {
      const delta = touchStart - e.touches[0].clientX;
      scrollRef.current.scrollLeft += delta / 3; // Smooth out the scrolling
    }
  };

  const handleTouchEnd = () => {
    const diff = touchStart - touchEnd;
    const threshold = 50; // Minimum swipe distance

    if (Math.abs(diff) > threshold) {
      scroll(diff > 0 ? 'right' : 'left');
    }
  };

  const handleCategoryClick = (category: { name: string, path: string }) => {
    if (selectedCategory === category.name) {
      onHomeClick();
      navigate('/', { replace: true });
    } else {
      onCategorySelect(category.name);
      navigate(category.path);
    }
  };

  return (
    <div className="bg-white border-b shadow-sm mt-16">
      <div className="max-w-7xl mx-auto relative">
        {/* Scroll Arrows - Only visible on desktop */}
        <div className="hidden md:block">
          {showLeftArrow && (
            <button
              onClick={() => scroll('left')}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/90 p-1 rounded-full shadow-md hover:bg-gray-100"
            >
              <ChevronLeft className="w-5 h-5 text-gray-600" />
            </button>
          )}
          {showRightArrow && (
            <button
              onClick={() => scroll('right')}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/90 p-1 rounded-full shadow-md hover:bg-gray-100"
            >
              <ChevronRight className="w-5 h-5 text-gray-600" />
            </button>
          )}
        </div>

        {/* Categories */}
        <div 
          ref={scrollRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          className="overflow-x-auto scrollbar-hide py-1 px-2 touch-pan-x"
          style={{
            WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
            scrollSnapType: 'x mandatory', // Enable snap scrolling
          }}
        >
          <div className="flex items-center gap-1 md:justify-center min-w-max">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => handleCategoryClick(category)}
                className={`group flex flex-col items-center px-3 py-2 transition-all duration-200 scroll-snap-align-start ${
                  selectedCategory === category.name 
                    ? 'bg-primary-50 text-primary-600' 
                    : 'hover:bg-gray-50 text-gray-600 hover:text-primary-600'
                }`}
              >
                <div className={`transition-all duration-200 ${
                  selectedCategory === category.name
                    ? 'text-primary-600'
                    : 'text-gray-500 group-hover:text-primary-600'
                }`}>
                  <category.icon className={`w-5 h-5 transition-transform duration-200 ${
                    selectedCategory === category.name 
                      ? 'transform scale-110'
                      : 'group-hover:scale-110'
                  }`} />
                </div>
                <span className={`text-xs font-medium whitespace-nowrap mt-1 transition-colors duration-200 ${
                  selectedCategory === category.name
                    ? 'text-primary-700'
                    : 'text-gray-600 group-hover:text-primary-600'
                }`}>
                  {category.name}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBar;