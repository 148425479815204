import React from 'react';
import { Building, MapPin, Shield, Camera } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const RealEstatePage = () => {
  const features = [
    {
      icon: MapPin,
      title: 'Location Info',
      description: 'Detailed area information'
    },
    {
      icon: Shield,
      title: 'Verified Listings',
      description: 'All properties verified'
    },
    {
      icon: Camera,
      title: 'Virtual Tours',
      description: 'Available for most properties'
    }
  ];

  return <CategoryPage category="Real Estate" icon={Building} features={features} />;
};

export default RealEstatePage;