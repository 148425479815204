import React from 'react';
import { Gift, Shield, Star, Camera } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const CollectiblesPage = () => {
  const features = [
    {
      icon: Shield,
      title: 'Authentication',
      description: 'Expert verification'
    },
    {
      icon: Star,
      title: 'Grading Available',
      description: 'Professional grading'
    },
    {
      icon: Camera,
      title: 'Detailed Photos',
      description: 'High-res documentation'
    }
  ];

  return <CategoryPage category="Collectibles" icon={Gift} features={features} />;
};

export default CollectiblesPage;