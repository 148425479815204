import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { storage } from '../lib/storage';

interface User {
  id: string;
  name: string;
  email: string;
  avatar?: string;
  status: 'active' | 'suspended' | 'banned';
  joinDate: string;
  lastActive: string;
  totalListings: number;
  totalSales: number;
  rating: number;
}

interface Report {
  id: string;
  type: 'listing' | 'user' | 'message';
  targetId: string;
  reason: string;
  status: 'pending' | 'resolved' | 'dismissed';
  createdAt: string;
  reportedBy: string;
}

interface AdminState {
  isAdmin: boolean;
  users: User[];
  reports: Report[];
  checkAdmin: (email: string, password: string) => boolean;
  getStats: () => AdminStats;
  getReports: () => Report[];
  handleReport: (reportId: string, action: 'resolve' | 'dismiss') => void;
  getUsers: () => User[];
  updateUserStatus: (userId: string, status: User['status']) => void;
  addUser: (user: Omit<User, 'id'>) => void;
  removeUser: (userId: string) => void;
}

interface AdminStats {
  totalUsers: number;
  totalListings: number;
  activeListings: number;
  totalReports: number;
  pendingReports: number;
}

const mockUsers: User[] = [
  {
    id: '1',
    name: 'John Doe',
    email: 'john@example.com',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    status: 'active',
    joinDate: '2024-01-15',
    lastActive: '2024-03-20',
    totalListings: 15,
    totalSales: 8,
    rating: 4.5
  },
  {
    id: '2',
    name: 'Jane Smith',
    email: 'jane@example.com',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    status: 'active',
    joinDate: '2024-02-01',
    lastActive: '2024-03-19',
    totalListings: 23,
    totalSales: 12,
    rating: 4.8
  }
];

export const useAdminStore = create<AdminState>()(
  persist(
    (set, get) => ({
      isAdmin: false,
      users: mockUsers,
      reports: [],

      checkAdmin: (email: string, password: string) => {
        const isValid = email === 'admin@ynotpost.com' && password === 'admin123';
        set({ isAdmin: isValid });
        return isValid;
      },

      getStats: () => {
        const users = get().users;
        const reports = get().reports;
        const listings = storage.get('listings') || [];

        return {
          totalUsers: users.length,
          totalListings: listings.length,
          activeListings: listings.filter((l: any) => !l.sold).length,
          totalReports: reports.length,
          pendingReports: reports.filter((r: Report) => r.status === 'pending').length
        };
      },

      getReports: () => get().reports,

      handleReport: (reportId: string, action: 'resolve' | 'dismiss') => {
        set(state => ({
          reports: state.reports.map(report =>
            report.id === reportId
              ? { ...report, status: action === 'resolve' ? 'resolved' : 'dismissed' }
              : report
          )
        }));
      },

      getUsers: () => get().users,

      updateUserStatus: (userId: string, status: User['status']) => {
        set(state => ({
          users: state.users.map(user =>
            user.id === userId ? { ...user, status } : user
          )
        }));
      },

      addUser: (userData) => {
        const newUser = {
          ...userData,
          id: crypto.randomUUID()
        };
        set(state => ({
          users: [...state.users, newUser]
        }));
      },

      removeUser: (userId: string) => {
        set(state => ({
          users: state.users.filter(user => user.id !== userId)
        }));
      }
    }),
    {
      name: 'admin-storage',
      version: 1,
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            version: 1,
            isAdmin: false,
            users: persistedState.users || mockUsers,
            reports: persistedState.reports || []
          };
        }
        return persistedState;
      }
    }
  )
);