import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { storage } from '../lib/storage';
import { testUsers } from '../utils/testUsers';

interface User {
  id: string;
  name: string;
  email: string;
  role: 'user' | 'admin' | 'moderator';
  avatar?: string;
  permissions?: string[];
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  adminSignIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
  hasPermission: (permission: string) => boolean;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,
      isAdmin: false,

      signIn: async (email: string, password: string) => {
        // Check for test users first
        const buyerMatch = email === testUsers.buyer.email && password === testUsers.buyer.password;
        const sellerMatch = email === testUsers.seller.email && password === testUsers.seller.password;

        if (buyerMatch || sellerMatch) {
          const testUser = buyerMatch ? testUsers.buyer : testUsers.seller;
          const user = {
            id: testUser.id,
            name: testUser.name,
            email: testUser.email,
            role: testUser.role,
            avatar: testUser.avatar
          };

          const existingProfile = storage.getProfile(user.id);
          if (!existingProfile) {
            storage.createDefaultProfile(user.id);
          }

          set({ user, isAuthenticated: true, isAdmin: false });
          return;
        }

        // Regular user authentication
        if (email && password) {
          const user = {
            id: crypto.randomUUID(),
            name: email.split('@')[0],
            email,
            role: 'user'
          };

          const existingProfile = storage.getProfile(user.id);
          if (!existingProfile) {
            storage.createDefaultProfile(user.id);
          }

          set({ user, isAuthenticated: true, isAdmin: false });
        } else {
          throw new Error('Invalid credentials');
        }
      },

      adminSignIn: async (email: string, password: string) => {
        if (email === 'admin@ynotpost.com' && password === 'admin123') {
          const adminUser = {
            id: 'admin',
            name: 'Admin',
            email,
            role: 'admin',
            permissions: [
              'manage_users',
              'manage_listings',
              'manage_categories',
              'manage_reports',
              'manage_settings',
              'view_analytics',
              'moderate_content'
            ]
          };
          set({ user: adminUser, isAuthenticated: true, isAdmin: true });
        } else if (email === 'moderator@ynotpost.com' && password === 'mod123') {
          const modUser = {
            id: 'moderator',
            name: 'Moderator',
            email,
            role: 'moderator',
            permissions: [
              'manage_listings',
              'manage_reports',
              'moderate_content'
            ]
          };
          set({ user: modUser, isAuthenticated: true, isAdmin: true });
        } else {
          throw new Error('Invalid admin credentials');
        }
      },

      signUp: async (email: string, password: string, name: string) => {
        if (email && password && name) {
          const user = {
            id: crypto.randomUUID(),
            name,
            email,
            role: 'user'
          };

          storage.createDefaultProfile(user.id);
          set({ user, isAuthenticated: true, isAdmin: false });
        } else {
          throw new Error('Invalid registration details');
        }
      },

      signOut: () => {
        set({ user: null, isAuthenticated: false, isAdmin: false });
      },

      hasPermission: (permission: string) => {
        const { user } = get();
        if (!user || !user.permissions) return false;
        return user.permissions.includes(permission);
      }
    }),
    {
      name: 'auth-storage',
      version: 1,
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            version: 1,
            isAdmin: false,
            user: persistedState.user || null,
            isAuthenticated: persistedState.isAuthenticated || false
          };
        }
        return persistedState;
      }
    }
  )
);