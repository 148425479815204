import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import ImageUpload from './ImageUpload';
import CategorySelect from './CategorySelect';
import DetailsForm from './DetailsForm';
import PricingForm from './PricingForm';
import LocationForm from './LocationForm';
import { validatePost, errorHandler } from '../../utils/errorHandling';
import { useListingStore } from '../../store/listingStore';
import { useAuthStore } from '../../store/authStore';
import toast from 'react-hot-toast';

interface PostFormProps {
  onClose: () => void;
  onPostCreated: (post: any) => void;
  listing?: any;
}

type Step = 'images' | 'category' | 'details' | 'pricing' | 'location';

const PostForm: React.FC<PostFormProps> = ({ onClose, onPostCreated, listing }) => {
  const { user } = useAuthStore();
  const addListing = useListingStore(state => state.addListing);
  const updateListing = useListingStore(state => state.updateListing);
  const [currentStep, setCurrentStep] = useState<Step>('images');
  const [formData, setFormData] = useState({
    images: listing?.images || [],
    category: listing?.category || '',
    subcategory: listing?.subcategory || '',
    title: listing?.title || '',
    description: listing?.description || '',
    condition: listing?.condition || '',
    brand: listing?.brand || '',
    price: listing?.price?.toString() || '',
    negotiable: listing?.negotiable || false,
    shippingAvailable: listing?.shippingAvailable || false,
    location: listing?.location || '',
    meetupPreference: listing?.meetupPreference || ''
  });

  useEffect(() => {
    if (listing) {
      setFormData({
        images: listing.images || [],
        category: listing.category || '',
        subcategory: listing.subcategory || '',
        title: listing.title || '',
        description: listing.description || '',
        condition: listing.condition || '',
        brand: listing.brand || '',
        price: listing.price?.toString() || '',
        negotiable: listing.negotiable || false,
        shippingAvailable: listing.shippingAvailable || false,
        location: listing.location || '',
        meetupPreference: listing.meetupPreference || ''
      });
    }
  }, [listing]);

  const updateFormData = (data: Partial<typeof formData>) => {
    setFormData({ ...formData, ...data });
  };

  const handleSubmit = () => {
    try {
      validatePost(formData);

      const listingData = {
        userId: user?.id,
        title: formData.title,
        price: Number(formData.price),
        description: formData.description,
        images: formData.images,
        location: formData.location,
        category: formData.category,
        subcategory: formData.subcategory,
        condition: formData.condition,
        brand: formData.brand,
        negotiable: formData.negotiable,
        shippingAvailable: formData.shippingAvailable,
        meetupPreference: formData.meetupPreference,
        seller: {
          id: user?.id || '',
          name: user?.name || '',
          rating: 0,
          reviews: 0,
          avatar: user?.avatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e"
        }
      };

      if (listing) {
        updateListing(listing.id, listingData);
        toast.success('Listing updated successfully!');
      } else {
        addListing(listingData);
        toast.success('Listing created successfully!');
      }

      onPostCreated(listingData);
      onClose();
    } catch (error) {
      errorHandler.handle(error as Error);
    }
  };

  const steps: Step[] = ['images', 'category', 'details', 'pricing', 'location'];
  const currentStepIndex = steps.indexOf(currentStep);

  const goToNextStep = () => {
    try {
      switch (currentStep) {
        case 'images':
          if (formData.images.length === 0) {
            throw new Error('Please add at least one image');
          }
          break;
        case 'category':
          if (!formData.category) {
            throw new Error('Please select a category');
          }
          break;
        case 'details':
          if (!formData.title || !formData.description) {
            throw new Error('Please fill in all required fields');
          }
          break;
        case 'pricing':
          if (!formData.price || isNaN(Number(formData.price))) {
            throw new Error('Please enter a valid price');
          }
          break;
      }

      if (currentStepIndex < steps.length - 1) {
        setCurrentStep(steps[currentStepIndex + 1]);
      }
    } catch (error) {
      errorHandler.handle(error as Error);
    }
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStep(steps[currentStepIndex - 1]);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col">
      <div className="flex justify-between items-center p-4 bg-white">
        <h2 className="text-xl font-semibold">
          {listing ? 'Edit Listing' : 'Create New Listing'}
        </h2>
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <X className="h-6 w-6" />
        </button>
      </div>

      <div className="flex-1 overflow-y-auto bg-white">
        <div className="max-w-2xl mx-auto p-4">
          {/* Progress bar */}
          <div className="mb-8">
            <div className="flex justify-between mb-2">
              {steps.map((step, index) => (
                <div
                  key={step}
                  className={`flex-1 h-2 rounded-full mx-1 ${
                    index <= currentStepIndex
                      ? 'bg-primary-600'
                      : 'bg-gray-200'
                  }`}
                />
              ))}
            </div>
            <div className="flex justify-between text-sm text-gray-600">
              <span>Photos</span>
              <span>Category</span>
              <span>Details</span>
              <span>Price</span>
              <span>Location</span>
            </div>
          </div>

          {/* Step content */}
          <div className="mb-8">
            {currentStep === 'images' && (
              <ImageUpload
                images={formData.images}
                onChange={(images) => updateFormData({ images })}
              />
            )}
            {currentStep === 'category' && (
              <CategorySelect
                category={formData.category}
                subcategory={formData.subcategory}
                onChange={(category, subcategory) =>
                  updateFormData({ category, subcategory })
                }
              />
            )}
            {currentStep === 'details' && (
              <DetailsForm
                title={formData.title}
                description={formData.description}
                condition={formData.condition}
                brand={formData.brand}
                onChange={updateFormData}
              />
            )}
            {currentStep === 'pricing' && (
              <PricingForm
                price={formData.price}
                negotiable={formData.negotiable}
                shippingAvailable={formData.shippingAvailable}
                onChange={updateFormData}
              />
            )}
            {currentStep === 'location' && (
              <LocationForm
                location={formData.location}
                meetupPreference={formData.meetupPreference}
                onChange={updateFormData}
              />
            )}
          </div>
        </div>
      </div>

      {/* Fixed bottom navigation */}
      <div className="flex justify-between items-center p-4 bg-white border-t">
        <button
          onClick={goToPreviousStep}
          className={`px-6 py-2 rounded-lg ${
            currentStepIndex === 0
              ? 'invisible'
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          Back
        </button>
        <button
          onClick={
            currentStepIndex === steps.length - 1
              ? handleSubmit
              : goToNextStep
          }
          className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
        >
          {currentStepIndex === steps.length - 1 ? (listing ? 'Update Listing' : 'Create Listing') : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default PostForm;