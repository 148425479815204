import React from 'react';
import { Baby, Shield, Tag, Package } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const BabyKidsPage = () => {
  const features = [
    {
      icon: Shield,
      title: 'Safety First',
      description: 'All items safety checked'
    },
    {
      icon: Tag,
      title: 'Brand Verified',
      description: 'Authentic products only'
    },
    {
      icon: Package,
      title: 'Clean & Sanitized',
      description: 'Hygiene guaranteed'
    }
  ];

  return <CategoryPage category="Baby & Kids" icon={Baby} features={features} />;
};

export default BabyKidsPage;