import React from 'react';
import { MessageSquare, Search } from 'lucide-react';

interface EmptyStateProps {
  filter: string;
  search: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ filter, search }) => {
  return (
    <div className="flex-1 flex flex-col items-center justify-center text-gray-500 p-8">
      {search ? (
        <>
          <Search className="w-12 h-12 mb-4" />
          <p className="text-lg font-medium">No messages found</p>
          <p className="text-sm text-center mt-2">
            No messages match your search for "{search}"
          </p>
        </>
      ) : (
        <>
          <MessageSquare className="w-12 h-12 mb-4" />
          <p className="text-lg font-medium">No messages</p>
          <p className="text-sm text-center mt-2">
            {filter === 'pending'
              ? 'No pending offers'
              : filter === 'active'
              ? 'No active transactions'
              : filter === 'completed'
              ? 'No completed transactions'
              : 'Start a conversation by viewing a listing'}
          </p>
        </>
      )}
    </div>
  );
};

export default EmptyState;