import React from 'react';
import { Gamepad, Shield, Video, Zap } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const GamingPage = () => {
  const features = [
    {
      icon: Video,
      title: 'Testing Available',
      description: 'Test before purchase'
    },
    {
      icon: Shield,
      title: 'Verified Games',
      description: 'Authentic games only'
    },
    {
      icon: Zap,
      title: 'Quick Delivery',
      description: 'Fast local pickup'
    }
  ];

  return <CategoryPage category="Gaming" icon={Gamepad} features={features} />;
};

export default GamingPage;