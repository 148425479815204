import React from 'react';
import { Check, DollarSign } from 'lucide-react';

interface ActionButtonsProps {
  isSeller: boolean;
  lastOffer?: {
    amount: number;
    by: 'buyer' | 'seller';
  };
  onAccept: () => void;
  onOffer: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isSeller,
  lastOffer,
  onAccept,
  onOffer
}) => {
  if (!lastOffer) {
    return (
      <div className="flex gap-2 mt-2">
        <button
          onClick={onOffer}
          className="px-3 py-1 bg-primary-600 text-white rounded-lg hover:bg-primary-700 text-sm flex items-center gap-1"
        >
          <DollarSign className="w-4 h-4" />
          Offer
        </button>
        <button
          onClick={onAccept}
          className="px-3 py-1 bg-green-600 text-white rounded-lg hover:bg-green-700 text-sm flex items-center gap-1"
        >
          <Check className="w-4 h-4" />
          Accept
        </button>
      </div>
    );
  }

  if (isSeller && lastOffer.by === 'buyer') {
    return (
      <button
        onClick={onAccept}
        className="px-3 py-1 bg-green-600 text-white rounded-lg hover:bg-green-700 text-sm flex items-center gap-1"
      >
        <Check className="w-4 h-4" />
        Accept ${lastOffer.amount}
      </button>
    );
  }

  return null;
};

export default ActionButtons;