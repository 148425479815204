import { toast } from 'react-hot-toast';

export const validatePost = (post: any) => {
  if (!post.title) throw new Error('Title is required');
  if (!post.price) throw new Error('Price is required');
  if (!post.images.length) throw new Error('At least one image is required');
};

export const validateOffer = (amount: string, listingPrice: number) => {
  const offerAmount = Number(amount);
  if (isNaN(offerAmount)) throw new Error('Please enter a valid amount');
  if (offerAmount <= 0) throw new Error('Offer must be greater than 0');
  if (offerAmount > listingPrice * 1.5) throw new Error('Offer seems unusually high');
};

export const errorHandler = {
  handle: (error: Error) => {
    console.error('Error:', error);
    toast.error(error.message || 'An unexpected error occurred');
  }
};