import React, { useState } from 'react';
import { Heart, X } from 'lucide-react';
import { useFavoriteStore } from '../store/favoriteStore';
import PostCard from './PostCard';
import PostDetail from './PostDetail';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const SavedItems: React.FC = () => {
  const navigate = useNavigate();
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const { favorites, removeFavorite } = useFavoriteStore();

  const handleRemove = (itemId: string) => {
    removeFavorite(itemId);
    toast.success('Removed from favorites');
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 mt-20">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <Heart className="w-6 h-6 text-primary-600" />
          <h2 className="text-2xl font-bold text-gray-900">Saved Items</h2>
        </div>
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-500">
            {favorites.length} {favorites.length === 1 ? 'item' : 'items'}
          </span>
          <button
            onClick={() => navigate('/')}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
      </div>

      {favorites.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {favorites.map((item) => (
            <div key={item.id} className="relative group">
              <PostCard
                post={item}
                onPostClick={() => setSelectedPost(item)}
                showHeart={true}
              />
              <button
                onClick={() => handleRemove(item.id)}
                className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-sm opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-50"
              >
                <X className="w-4 h-4 text-red-500" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <Heart className="w-12 h-12 text-gray-300 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No saved items yet</h3>
          <p className="text-gray-500">
            Items you save will appear here
          </p>
          <button
            onClick={() => navigate('/')}
            className="mt-4 px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
          >
            Browse Items
          </button>
        </div>
      )}

      {selectedPost && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative w-full max-w-4xl mx-auto my-8">
            <PostDetail
              post={selectedPost}
              onClose={() => setSelectedPost(null)}
              onProfileClick={() => navigate('/profile')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SavedItems;