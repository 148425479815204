import React from 'react';
import { MessageSquare } from 'lucide-react';

interface QuickMessagesProps {
  onSelect: (message: string) => void;
}

const QuickMessages: React.FC<QuickMessagesProps> = ({ onSelect }) => {
  const messages = {
    greetings: [
      "Hi! I'm interested in your item",
      "Hello! Is this still available?",
      "Hi there! When can we meet?"
    ],
    negotiation: [
      "Would you consider my offer?",
      "Is the price negotiable?",
      "Can you do a better price?",
      "That's my best offer"
    ],
    meetup: [
      "Can we meet at a public place?",
      "Is [location] convenient for you?",
      "I'm available today after 5pm",
      "Let's meet at a safe location"
    ],
    confirmation: [
      "Great! I'll take it",
      "Perfect! When can we meet?",
      "Sounds good! See you then",
      "Thanks! Looking forward to it"
    ]
  };

  return (
    <div className="p-2 bg-gray-50 rounded-lg">
      <div className="flex items-center gap-2 mb-2 px-2">
        <MessageSquare className="w-4 h-4 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">Quick Messages</span>
      </div>
      <div className="grid grid-cols-2 gap-1">
        {Object.entries(messages).map(([category, msgs]) => (
          <div key={category} className="space-y-1">
            {msgs.map((msg, index) => (
              <button
                key={index}
                onClick={() => onSelect(msg)}
                className="w-full text-left px-3 py-2 text-sm text-gray-600 hover:bg-white rounded-lg transition-colors"
              >
                {msg}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuickMessages;