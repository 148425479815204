import React, { useState } from 'react';
import { MessageSquare, Plus, Home, Heart, User, LogOut, Search, Menu, X } from 'lucide-react';
import { useFavoriteStore } from '../store/favoriteStore';
import { useAuthStore } from '../store/authStore';
import { useNavigate } from 'react-router-dom';
import AuthModal from './Auth/AuthModal';
import SearchBar from './SearchBar';
import Logo from './Logo';
import toast from 'react-hot-toast';

interface NavbarProps {
  onPostClick: () => void;
  onMessageClick: () => void;
  onHomeClick: () => void;
  onSearch: (query: string, results: any[]) => void;
}

const Navbar: React.FC<NavbarProps> = ({
  onPostClick,
  onMessageClick,
  onHomeClick,
  onSearch
}) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const { favorites } = useFavoriteStore();
  const { isAuthenticated, signOut } = useAuthStore();
  const navigate = useNavigate();

  const handleAuthAction = () => {
    if (isAuthenticated) {
      signOut();
      navigate('/');
      toast.success('Logged out successfully');
    } else {
      setShowAuthModal(true);
    }
  };

  const handleAuthSuccess = () => {
    setShowAuthModal(false);
    toast.success('Welcome back!');
  };

  const handleHomeClick = () => {
    navigate('/');
    onHomeClick();
    setShowMobileMenu(false);
    setShowMobileSearch(false);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 bg-white shadow-sm z-50">
        {/* Desktop Navigation */}
        <div className="hidden md:block">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <div className="flex items-center space-x-8">
                <button 
                  onClick={handleHomeClick}
                  className="text-2xl font-bold text-primary-600 hover:text-primary-700"
                >
                  <Logo variant="stack" size="md" />
                </button>
                <button
                  onClick={handleHomeClick}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  aria-label="Home"
                >
                  <Home className="h-6 w-6 text-gray-600" />
                </button>
              </div>

              <SearchBar onSearch={onSearch} onClear={onHomeClick} />

              <div className="flex items-center space-x-4">
                {isAuthenticated ? (
                  <>
                    <button
                      onClick={onMessageClick}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors relative"
                      aria-label="Messages"
                    >
                      <MessageSquare className="h-6 w-6 text-gray-600" />
                    </button>
                    <button
                      onClick={() => navigate('/saved-items')}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors relative"
                      aria-label="Saved Items"
                    >
                      <Heart className="h-6 w-6 text-gray-600" />
                      {favorites.length > 0 && (
                        <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                          {favorites.length}
                        </span>
                      )}
                    </button>
                    <button
                      onClick={() => navigate('/profile')}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                      aria-label="Profile"
                    >
                      <User className="h-6 w-6 text-gray-600" />
                    </button>
                    <button
                      onClick={handleAuthAction}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                      aria-label="Sign Out"
                    >
                      <LogOut className="h-6 w-6 text-gray-600" />
                    </button>
                    <button
                      onClick={onPostClick}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors"
                    >
                      <Plus className="h-5 w-5 mr-1" />
                      Sell Now
                    </button>
                  </>
                ) : (
                  <button
                    onClick={handleAuthAction}
                    className="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors"
                  >
                    Sign In
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden">
          <div className="px-4 py-3">
            <div className="grid grid-cols-3 items-center">
              <button 
                onClick={() => setShowMobileMenu(!showMobileMenu)} 
                className="justify-self-start p-2"
              >
                {showMobileMenu ? (
                  <X className="h-6 w-6 text-gray-600" />
                ) : (
                  <Menu className="h-6 w-6 text-gray-600" />
                )}
              </button>
              
              <button 
                onClick={handleHomeClick}
                className="justify-self-center flex items-center"
              >
                <Logo variant="stack" size="md" />
              </button>
              
              <div className="justify-self-end flex items-center gap-2">
                <button
                  onClick={() => setShowMobileSearch(!showMobileSearch)}
                  className="p-2"
                >
                  <Search className="h-6 w-6 text-gray-600" />
                </button>
                {isAuthenticated && (
                  <button onClick={onPostClick} className="p-2">
                    <Plus className="h-6 w-6 text-primary-600" />
                  </button>
                )}
              </div>
            </div>

            {showMobileSearch && (
              <div className="mt-3">
                <SearchBar onSearch={onSearch} onClear={onHomeClick} />
              </div>
            )}
          </div>

          {/* Mobile Menu */}
          {showMobileMenu && (
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="px-4 space-y-1">
                {isAuthenticated ? (
                  <>
                    <button
                      onClick={() => {
                        navigate('/profile');
                        setShowMobileMenu(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-100"
                    >
                      Profile
                    </button>
                    <button
                      onClick={() => {
                        navigate('/saved-items');
                        setShowMobileMenu(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-100"
                    >
                      Saved Items
                    </button>
                    <button
                      onClick={() => {
                        onMessageClick();
                        setShowMobileMenu(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-100"
                    >
                      Messages
                    </button>
                    <button
                      onClick={() => {
                        handleAuthAction();
                        setShowMobileMenu(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-100"
                    >
                      Sign Out
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      handleAuthAction();
                      setShowMobileMenu(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-100"
                  >
                    Sign In
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </nav>

      {showAuthModal && (
        <AuthModal 
          onClose={() => setShowAuthModal(false)} 
          onSuccess={handleAuthSuccess}
        />
      )}
    </>
  );
};

export default Navbar;