import React from 'react';
import { X, Facebook, Twitter, MessageCircle, Copy, Link2, Mail } from 'lucide-react';
import toast from 'react-hot-toast';

interface ShareModalProps {
  listing: {
    id: string;
    title: string;
    price: number;
    images: string[];
  };
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ listing, onClose }) => {
  const shareUrl = `${window.location.origin}/listing/${listing.id}`;
  const shareText = `Check out ${listing.title} for $${listing.price} on YnotPost`;

  const shareOptions = [
    {
      name: 'Facebook',
      icon: Facebook,
      color: 'bg-[#1877F2] text-white',
      onClick: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
          '_blank'
        );
      }
    },
    {
      name: 'Twitter',
      icon: Twitter,
      color: 'bg-[#1DA1F2] text-white',
      onClick: () => {
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`,
          '_blank'
        );
      }
    },
    {
      name: 'WhatsApp',
      icon: MessageCircle,
      color: 'bg-[#25D366] text-white',
      onClick: () => {
        window.open(
          `https://wa.me/?text=${encodeURIComponent(shareText + '\n' + shareUrl)}`,
          '_blank'
        );
      }
    },
    {
      name: 'Email',
      icon: Mail,
      color: 'bg-gray-600 text-white',
      onClick: () => {
        window.location.href = `mailto:?subject=${encodeURIComponent(listing.title)}&body=${encodeURIComponent(shareText + '\n\n' + shareUrl)}`;
      }
    }
  ];

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Share Listing</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="p-6">
          {/* Preview */}
          <div className="flex gap-4 p-4 bg-gray-50 rounded-lg mb-6">
            <img
              src={listing.images[0]}
              alt={listing.title}
              className="w-16 h-16 rounded-lg object-cover"
            />
            <div>
              <h3 className="font-medium text-gray-900">{listing.title}</h3>
              <p className="text-gray-600">${listing.price}</p>
            </div>
          </div>

          {/* Share Options */}
          <div className="grid grid-cols-2 gap-4">
            {shareOptions.map((option) => (
              <button
                key={option.name}
                onClick={option.onClick}
                className={`flex items-center justify-center gap-2 p-3 rounded-lg ${option.color} hover:opacity-90 transition-opacity`}
              >
                <option.icon className="w-5 h-5" />
                <span>{option.name}</span>
              </button>
            ))}
          </div>

          {/* Copy Link */}
          <div className="mt-6">
            <div className="flex gap-2">
              <input
                type="text"
                value={shareUrl}
                readOnly
                className="flex-1 px-4 py-2 border rounded-lg bg-gray-50"
              />
              <button
                onClick={copyToClipboard}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 flex items-center gap-2"
              >
                <Link2 className="w-5 h-5" />
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;