import React from 'react';

interface DetailsFormProps {
  title: string;
  description: string;
  condition: string;
  brand: string;
  onChange: (data: Partial<{
    title: string;
    description: string;
    condition: string;
    brand: string;
  }>) => void;
}

const conditions = [
  'New with tags',
  'New without tags',
  'Like new',
  'Good',
  'Fair',
  'For parts'
];

function DetailsForm({ title, description, condition, brand, onChange }: DetailsFormProps) {
  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => onChange({ title: e.target.value })}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500"
          placeholder="What are you selling?"
          maxLength={100}
        />
        <p className="mt-1 text-sm text-gray-500">
          {100 - title.length} characters remaining
        </p>
      </div>

      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => onChange({ description: e.target.value })}
          rows={4}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500"
          placeholder="Describe your item in detail..."
        />
      </div>

      <div>
        <label htmlFor="brand" className="block text-sm font-medium text-gray-700 mb-1">
          Brand (optional)
        </label>
        <input
          type="text"
          id="brand"
          value={brand}
          onChange={(e) => onChange({ brand: e.target.value })}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500"
          placeholder="Enter brand name"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Condition
        </label>
        <div className="grid grid-cols-2 gap-3">
          {conditions.map((cond) => (
            <button
              key={cond}
              type="button"
              onClick={() => onChange({ condition: cond })}
              className={`px-4 py-2 rounded-lg border ${
                condition === cond
                  ? 'border-emerald-600 bg-emerald-50 text-emerald-700'
                  : 'border-gray-300 text-gray-700 hover:border-emerald-600'
              }`}
            >
              {cond}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DetailsForm;