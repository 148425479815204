import React, { useState, useRef, useEffect } from 'react';
import { Camera, Upload, X } from 'lucide-react';
import toast from 'react-hot-toast';

const CameraSwitchIcon = () => (
  <svg 
    width="28" 
    height="28" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className="text-white"
  >
    <path
      d="M20 4h-3.17L15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 11.5V13H9v2.5L5.5 12 9 8.5V11h6V8.5l3.5 3.5-3.5 3.5z"
      fill="currentColor"
    />
  </svg>
);

interface ImageUploadProps {
  images: string[];
  onChange: (images: string[]) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ images, onChange }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isUsingCamera, setIsUsingCamera] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [usingFrontCamera, setUsingFrontCamera] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  const startCamera = async (useFrontCamera: boolean = false) => {
    try {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }

      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: useFrontCamera ? 'user' : { exact: 'environment' },
          width: { ideal: 1920 },
          height: { ideal: 1920 }
        }
      };

      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        await videoRef.current.play();
      }
      setIsUsingCamera(true);
      setUsingFrontCamera(useFrontCamera);
    } catch (error) {
      console.error('Camera error:', error);
      if (!useFrontCamera) {
        await startCamera(true);
      } else {
        toast.error('Unable to access camera. Please check permissions.');
      }
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
    setIsUsingCamera(false);
  };

  const switchCamera = async () => {
    setUsingFrontCamera(!usingFrontCamera);
    await startCamera(!usingFrontCamera);
  };

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      if (!context) return;

      canvas.width = 600;
      canvas.height = 600;

      // Fill with white background
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, 600, 600);

      // Calculate dimensions for center crop
      const size = Math.min(video.videoWidth, video.videoHeight);
      const startX = (video.videoWidth - size) / 2;
      const startY = (video.videoHeight - size) / 2;

      context.save();
      if (!usingFrontCamera) {
        // Flip horizontally for back camera
        context.scale(-1, 1);
        context.translate(-600, 0);
      }

      // Draw the center-cropped image
      context.drawImage(
        video,
        startX, startY, size, size, // Source rectangle
        0, 0, 600, 600 // Destination rectangle
      );

      context.restore();

      // Convert to base64
      const photoData = canvas.toDataURL('image/jpeg', 0.9);
      
      // Add to images array
      const newImages = [...images, photoData];
      onChange(newImages);

      // Auto-proceed if we have 8 photos
      if (newImages.length >= 8) {
        stopCamera();
        toast.success('Maximum photos reached!');
      } else {
        toast.success(`Photo ${newImages.length}/8 captured`);
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length + images.length > 8) {
      toast.error(`You can only upload up to ${8 - images.length} more photos`);
      return;
    }

    files.forEach(file => {
      if (!file.type.startsWith('image/')) {
        toast.error(`${file.name} is not an image file`);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (!ctx) return;

          canvas.width = 600;
          canvas.height = 600;

          // Fill white background
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, 600, 600);

          // Calculate dimensions for center crop
          const size = Math.min(img.width, img.height);
          const startX = (img.width - size) / 2;
          const startY = (img.height - size) / 2;

          // Draw center-cropped image
          ctx.drawImage(
            img,
            startX, startY, size, size,
            0, 0, 600, 600
          );

          const resizedImage = canvas.toDataURL('image/jpeg', 0.9);
          onChange([...images, resizedImage]);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index: number) => {
    onChange(images.filter((_, i) => i !== index));
  };

  if (isUsingCamera) {
    return (
      <div className="fixed inset-0 bg-black z-50">
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="w-full h-full object-cover"
        />
        <canvas ref={canvasRef} className="hidden" />

        <div className="absolute inset-0 flex flex-col">
          <div className="p-4 flex justify-between items-center bg-gradient-to-b from-black/50 to-transparent">
            <button
              onClick={stopCamera}
              className="p-2 text-white"
            >
              <X className="w-6 h-6" />
            </button>
            <span className="text-white font-medium">
              {images.length}/8 Photos
            </span>
          </div>

          <div className="flex-1" />

          <div className="p-4 bg-gradient-to-t from-black/50 to-transparent">
            <div className="mb-4 overflow-x-auto whitespace-nowrap">
              {images.map((img, index) => (
                <div key={index} className="inline-block mr-2 relative">
                  <img
                    src={img}
                    alt={`Photo ${index + 1}`}
                    className="w-16 h-16 rounded object-cover"
                  />
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute top-2 right-2 p-1.5 bg-black/50 rounded-full text-white transition-transform active:scale-95"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-center gap-8">
              <button
                onClick={capturePhoto}
                disabled={images.length >= 8}
                className="w-16 h-16 rounded-full bg-white disabled:bg-gray-400 flex items-center justify-center transition-transform active:scale-95"
              >
                <div className="w-14 h-14 rounded-full border-2 border-gray-800" />
              </button>
              {isMobile && (
                <button
                  onClick={switchCamera}
                  className="p-2 text-white"
                  title={usingFrontCamera ? "Switch to back camera" : "Switch to front camera"}
                >
                  <CameraSwitchIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="text-center">
        <h3 className="text-lg font-semibold mb-2">Add Photos</h3>
        <p className="text-gray-500 text-sm">Add up to 8 photos of your item</p>
      </div>

      <div className="flex flex-col gap-3">
        {isMobile && (
          <button
            onClick={() => startCamera(false)}
            className="w-full py-3 bg-primary-600 text-white rounded-lg flex items-center justify-center gap-2 transition-transform active:scale-95"
          >
            <Camera className="w-5 h-5" />
            Take Photos
          </button>
        )}

        <button
          onClick={() => fileInputRef.current?.click()}
          className="w-full py-3 bg-gray-100 text-gray-700 rounded-lg flex items-center justify-center gap-2 transition-transform active:scale-95"
        >
          <Upload className="w-5 h-5" />
          Upload Photos
        </button>

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileUpload}
          className="hidden"
        />
      </div>

      {images.length > 0 && (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div key={index} className="relative aspect-square">
              <img
                src={image}
                alt={`Upload ${index + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                onClick={() => removeImage(index)}
                className="absolute top-2 right-2 p-1.5 bg-black/50 rounded-full text-white transition-transform active:scale-95"
              >
                <X className="w-4 h-4" />
              </button>
              {index === 0 && (
                <span className="absolute bottom-2 left-2 px-2 py-1 bg-primary-500 text-white text-xs rounded-full">
                  Cover Photo
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;