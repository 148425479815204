import React from 'react';
import { Heart, MapPin, Star } from 'lucide-react';
import { useFavoriteStore } from '../store/favoriteStore';
import toast from 'react-hot-toast';

interface PostCardProps {
  post: {
    id: string;
    title: string;
    price: number;
    description: string;
    images: string[];
    location: string;
    category: string;
    rating: number;
    reviews: number;
    views: number;
    comments: number;
    seller: {
      name: string;
      rating: number;
      avatar: string;
    };
  };
  onPostClick?: () => void;
  showHeart?: boolean;
}

const PostCard: React.FC<PostCardProps> = ({ post, onPostClick, showHeart = false }) => {
  const { addFavorite, removeFavorite, isFavorite } = useFavoriteStore();
  const isLiked = isFavorite(post.id);

  const handleLike = (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      if (isLiked) {
        removeFavorite(post.id);
        toast.success('Removed from favorites');
      } else {
        const success = addFavorite({
          ...post,
          status: 'available'
        });
        if (success) {
          toast.success('Added to favorites');
        }
      }
    } catch (error) {
      console.error('Error handling favorite:', error);
      toast.error('Failed to update favorites');
    }
  };

  return (
    <div 
      onClick={onPostClick}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 cursor-pointer overflow-hidden"
    >
      <div className="relative aspect-[4/3]">
        <img 
          src={post.images[0]} 
          alt={post.title}
          className="w-full h-full object-cover"
        />
        {showHeart && (
          <button 
            onClick={handleLike}
            className="absolute top-2 right-2 p-1.5 rounded-full bg-white/90 hover:bg-white shadow-sm"
          >
            <Heart 
              className={`w-4 h-4 ${isLiked ? 'text-red-500 fill-current' : 'text-gray-600'}`} 
            />
          </button>
        )}
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-3">
          <p className="text-lg font-semibold text-white line-clamp-1">
            ${post.price.toLocaleString()}
          </p>
        </div>
      </div>

      <div className="p-3">
        <h3 className="font-medium text-gray-900 text-sm line-clamp-1 mb-1">
          {post.title}
        </h3>
        
        <div className="flex items-center justify-between text-xs text-gray-500">
          <div className="flex items-center gap-1">
            <MapPin className="w-3 h-3" />
            <span className="line-clamp-1">{post.location}</span>
          </div>
          <div className="flex items-center gap-1">
            <Star className="w-3 h-3 text-yellow-400 fill-current" />
            <span>{post.rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;