import React, { useState } from 'react';
import { Search, X, Filter, ArrowLeft } from 'lucide-react';
import MessageList from './MessageList';
import ChatWindow from './ChatWindow';
import EmptyState from './EmptyState';
import { useAuthStore } from '../../store/authStore';
import { useChatStore } from '../../store/chatStore';

interface MessageCenterProps {
  onClose: () => void;
}

const MessageCenter: React.FC<MessageCenterProps> = ({ onClose }) => {
  const { user } = useAuthStore();
  const { chats, deleteChat, togglePin, toggleImportant } = useChatStore();
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<'all' | 'important' | 'pinned'>('all');
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const filteredChats = chats
    .filter(chat => {
      const isParticipant = 
        chat.buyer.id === user?.id || 
        chat.seller.id === user?.id;

      const matchesSearch = 
        chat.listing.title.toLowerCase().includes(search.toLowerCase()) ||
        chat.seller.name.toLowerCase().includes(search.toLowerCase()) ||
        chat.buyer.name.toLowerCase().includes(search.toLowerCase());

      const matchesFilter = 
        filter === 'all' ||
        (filter === 'important' && chat.important) ||
        (filter === 'pinned' && chat.pinned);

      return isParticipant && matchesSearch && matchesFilter;
    })
    .sort((a, b) => {
      if (a.pinned && !b.pinned) return -1;
      if (!a.pinned && b.pinned) return 1;
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });

  const activeChat = chats.find(chat => chat.id === selectedChat);

  const handleDeleteChat = (chatId: string) => {
    if (window.confirm('Are you sure you want to delete this conversation?')) {
      deleteChat(chatId);
      if (selectedChat === chatId) {
        setSelectedChat(null);
      }
    }
  };

  const handleBackToList = () => {
    setSelectedChat(null);
  };

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col md:flex-row">
      {/* Messages List - Hidden on mobile when chat is selected */}
      <div className={`${selectedChat ? 'hidden md:flex' : 'flex'} w-full md:w-[400px] flex-col border-r bg-gray-50`}>
        {/* Header */}
        <div className="p-4 border-b bg-white flex justify-between items-center">
          <h2 className="text-xl font-semibold">Messages</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Search and Filters */}
        <div className="p-4 border-b bg-white space-y-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search messages..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <div className="flex gap-2">
            <button
              onClick={() => setFilter('all')}
              className={`px-3 py-1.5 rounded-lg text-sm ${
                filter === 'all'
                  ? 'bg-primary-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              All
            </button>
            <button
              onClick={() => setFilter('important')}
              className={`px-3 py-1.5 rounded-lg text-sm ${
                filter === 'important'
                  ? 'bg-primary-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              Important
            </button>
            <button
              onClick={() => setFilter('pinned')}
              className={`px-3 py-1.5 rounded-lg text-sm ${
                filter === 'pinned'
                  ? 'bg-primary-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              Pinned
            </button>
          </div>
        </div>

        {/* Message List */}
        {filteredChats.length > 0 ? (
          <MessageList
            chats={filteredChats}
            selectedChatId={selectedChat}
            onSelectChat={setSelectedChat}
            currentUserId={user?.id}
            onDeleteChat={handleDeleteChat}
            onTogglePin={togglePin}
            onToggleImportant={toggleImportant}
          />
        ) : (
          <EmptyState filter={filter} search={search} />
        )}
      </div>

      {/* Chat Window - Full screen on mobile when chat is selected */}
      <div className={`${selectedChat ? 'flex' : 'hidden md:flex'} flex-1 flex flex-col`}>
        {selectedChat && activeChat ? (
          <div className="flex-1 flex flex-col">
            {/* Mobile back button */}
            <div className="md:hidden flex items-center p-4 border-b bg-white">
              <button
                onClick={handleBackToList}
                className="p-2 hover:bg-gray-100 rounded-full mr-2"
              >
                <ArrowLeft className="w-6 h-6" />
              </button>
              <h2 className="text-lg font-semibold">Chat</h2>
            </div>
            <ChatWindow
              chat={activeChat}
              onClose={() => setSelectedChat(null)}
              currentUserId={user?.id}
            />
          </div>
        ) : (
          <div className="flex-1 hidden md:flex items-center justify-center text-gray-500">
            <div className="text-center">
              <p className="text-lg font-medium">Select a conversation</p>
              <p className="text-sm mt-2">
                Choose a chat from the left to start messaging
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageCenter;