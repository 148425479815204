import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { useListingStore } from './listingStore';

interface Chat {
  id: string;
  listingId: string;
  listing: {
    title: string;
    price: number;
    image: string;
    category: string;
    sold: boolean;
  };
  seller: {
    id: string;
    name: string;
    avatar: string;
  };
  buyer: {
    id: string;
    name: string;
    avatar: string;
  };
  messages: Array<{
    id: string;
    sender: string;
    text: string;
    type: 'text' | 'offer' | 'system';
    amount?: number;
    timestamp: string;
  }>;
  lastOffer?: {
    amount: number;
    by: 'buyer' | 'seller';
    timestamp: string;
  };
  pinned: boolean;
  important: boolean;
  createdAt: string;
  updatedAt: string;
}

interface ChatStore {
  chats: Chat[];
  initiateChat: (params: {
    listingId: string;
    sellerId: string;
    buyerId: string;
    type: 'direct' | 'offer';
    amount: number;
  }) => void;
  addMessage: (chatId: string, message: Omit<Chat['messages'][0], 'id' | 'timestamp'>) => void;
  deleteChat: (chatId: string) => void;
  togglePin: (chatId: string) => void;
  toggleImportant: (chatId: string) => void;
  acceptOffer: (chatId: string) => void;
  declineOffer: (chatId: string) => void;
  makeCounterOffer: (chatId: string, amount: number) => void;
  markAsSold: (chatId: string) => void;
}

export const useChatStore = create<ChatStore>()(
  persist(
    (set, get) => ({
      chats: [],

      initiateChat: ({ listingId, sellerId, buyerId, type, amount }) => {
        const listingStore = useListingStore.getState();
        const listing = listingStore.getAllListings().find(l => l.id === listingId);
        
        if (!listing) {
          toast.error('Listing not found');
          return;
        }

        // Check for existing chat
        const existingChat = get().chats.find(c => c.listingId === listingId);
        if (existingChat) {
          if (type === 'offer') {
            get().addMessage(existingChat.id, {
              sender: buyerId,
              text: `Offered $${amount}`,
              type: 'offer',
              amount
            });
            set(state => ({
              chats: state.chats.map(chat =>
                chat.id === existingChat.id
                  ? {
                      ...chat,
                      lastOffer: {
                        amount,
                        by: 'buyer',
                        timestamp: new Date().toISOString()
                      },
                      updatedAt: new Date().toISOString()
                    }
                  : chat
              )
            }));
          }
          return;
        }

        // Create new chat
        const newChat: Chat = {
          id: uuidv4(),
          listingId,
          listing: {
            title: listing.title,
            price: listing.price,
            image: listing.images[0],
            category: listing.category,
            sold: listing.sold
          },
          seller: listing.seller,
          buyer: {
            id: buyerId,
            name: 'Buyer',
            avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330'
          },
          messages: [
            {
              id: uuidv4(),
              sender: buyerId,
              text: type === 'offer' ? `Offered $${amount}` : 'I am interested in this item',
              type: type === 'offer' ? 'offer' : 'text',
              amount: type === 'offer' ? amount : undefined,
              timestamp: new Date().toISOString()
            }
          ],
          lastOffer: type === 'offer' ? {
            amount,
            by: 'buyer',
            timestamp: new Date().toISOString()
          } : undefined,
          pinned: false,
          important: type === 'offer',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        set(state => ({ chats: [newChat, ...state.chats] }));
        toast.success(type === 'offer' ? 'Offer sent!' : 'Message sent!');
      },

      addMessage: (chatId, message) => {
        const fullMessage = {
          ...message,
          id: uuidv4(),
          timestamp: new Date().toISOString()
        };

        set(state => ({
          chats: state.chats.map(chat =>
            chat.id === chatId
              ? {
                  ...chat,
                  messages: [...chat.messages, fullMessage],
                  updatedAt: new Date().toISOString()
                }
              : chat
          )
        }));
      },

      deleteChat: (chatId) => {
        set(state => ({
          chats: state.chats.filter(chat => chat.id !== chatId)
        }));
        toast.success('Chat deleted');
      },

      togglePin: (chatId) => {
        set(state => ({
          chats: state.chats.map(chat =>
            chat.id === chatId
              ? { ...chat, pinned: !chat.pinned }
              : chat
          )
        }));
      },

      toggleImportant: (chatId) => {
        set(state => ({
          chats: state.chats.map(chat =>
            chat.id === chatId
              ? { ...chat, important: !chat.important }
              : chat
          )
        }));
      },

      acceptOffer: (chatId) => {
        const chat = get().chats.find(c => c.id === chatId);
        if (!chat?.lastOffer) return;

        get().addMessage(chatId, {
          sender: 'system',
          text: `Offer of $${chat.lastOffer.amount} accepted!`,
          type: 'system'
        });

        set(state => ({
          chats: state.chats.map(chat =>
            chat.id === chatId
              ? {
                  ...chat,
                  listing: { ...chat.listing, sold: true },
                  lastOffer: undefined
                }
              : chat
          )
        }));

        toast.success('Offer accepted!');
      },

      declineOffer: (chatId) => {
        const chat = get().chats.find(c => c.id === chatId);
        if (!chat?.lastOffer) return;

        get().addMessage(chatId, {
          sender: 'system',
          text: `Offer of $${chat.lastOffer.amount} declined`,
          type: 'system'
        });

        set(state => ({
          chats: state.chats.map(chat =>
            chat.id === chatId
              ? { ...chat, lastOffer: undefined }
              : chat
          )
        }));

        toast.success('Offer declined');
      },

      makeCounterOffer: (chatId, amount) => {
        const chat = get().chats.find(c => c.id === chatId);
        if (!chat) return;

        get().addMessage(chatId, {
          sender: chat.seller.id,
          text: `Counter-offered $${amount}`,
          type: 'offer',
          amount
        });

        set(state => ({
          chats: state.chats.map(chat =>
            chat.id === chatId
              ? {
                  ...chat,
                  lastOffer: {
                    amount,
                    by: 'seller',
                    timestamp: new Date().toISOString()
                  }
                }
              : chat
          )
        }));

        toast.success('Counter-offer sent');
      },

      markAsSold: (chatId) => {
        set(state => ({
          chats: state.chats.map(chat =>
            chat.id === chatId
              ? {
                  ...chat,
                  listing: { ...chat.listing, sold: true }
                }
              : chat
          )
        }));

        get().addMessage(chatId, {
          sender: 'system',
          text: 'Item marked as sold! 🎉',
          type: 'system'
        });

        toast.success('Item marked as sold!');
      }
    }),
    {
      name: 'chat-storage',
      version: 1
    }
  )
);