import React from 'react';
import { 
  Facebook, Twitter, Instagram, Youtube, Mail, Phone,
  Shield, HelpCircle, FileText, Heart, Settings, AlertCircle
} from 'lucide-react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = {
    about: [
      { name: 'About Us', path: '/about' },
      { name: 'Careers', path: '/careers' },
      { name: 'Press', path: '/press' },
      { name: 'Blog', path: '/blog' }
    ],
    support: [
      { name: 'Help Center', path: '/help', icon: HelpCircle },
      { name: 'Safety Center', path: '/safety', icon: Shield },
      { name: 'Community Guidelines', path: '/guidelines', icon: FileText },
      { name: 'Report an Issue', path: '/report', icon: AlertCircle }
    ],
    legal: [
      { name: 'Terms of Service', path: '/terms' },
      { name: 'Privacy Policy', path: '/privacy' },
      { name: 'Cookie Policy', path: '/cookies' },
      { name: 'Accessibility', path: '/accessibility' }
    ]
  };

  const socialLinks = [
    { name: 'Facebook', icon: Facebook, url: '#', color: 'hover:text-[#1877F2]' },
    { name: 'Twitter', icon: Twitter, url: '#', color: 'hover:text-[#1DA1F2]' },
    { name: 'Instagram', icon: Instagram, url: '#', color: 'hover:text-[#E4405F]' },
    { name: 'YouTube', icon: Youtube, url: '#', color: 'hover:text-[#FF0000]' }
  ];

  return (
    <footer className="bg-white border-t mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Brand Column */}
          <div className="space-y-6">
            <Logo variant="stack" size="lg" />
            <p className="text-gray-500 text-sm">
              The trusted marketplace for buying and selling locally
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social) => (
                <a
                  key={social.name}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-gray-400 transition-colors ${social.color}`}
                >
                  <social.icon className="w-5 h-5" />
                </a>
              ))}
            </div>
          </div>

          {/* About Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              About
            </h3>
            <ul className="space-y-3">
              {footerLinks.about.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.path}
                    className="text-gray-500 hover:text-primary-600 text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Support Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Support
            </h3>
            <ul className="space-y-3">
              {footerLinks.support.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.path}
                    className="text-gray-500 hover:text-primary-600 text-sm flex items-center gap-2"
                  >
                    <link.icon className="w-4 h-4" />
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Column */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Contact Us
            </h3>
            <div className="space-y-3">
              <a
                href="mailto:support@ynotpost.com"
                className="text-gray-500 hover:text-primary-600 text-sm flex items-center gap-2"
              >
                <Mail className="w-4 h-4" />
                support@ynotpost.com
              </a>
              <a
                href="tel:1-800-123-4567"
                className="text-gray-500 hover:text-primary-600 text-sm flex items-center gap-2"
              >
                <Phone className="w-4 h-4" />
                1-800-123-4567
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 pt-8 border-t">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="text-gray-400 text-sm">
              © {currentYear} YnotPost. All rights reserved.
            </div>
            <div className="flex flex-wrap justify-center gap-4 text-sm">
              {footerLinks.legal.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  className="text-gray-500 hover:text-primary-600"
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile App Banner */}
      <div className="bg-primary-50 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center gap-4 text-sm">
            <span className="text-primary-600 font-medium">Get the YnotPost App</span>
            <div className="flex gap-2">
              <button className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900">
                App Store
              </button>
              <button className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900">
                Google Play
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;