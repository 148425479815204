import React from 'react';
import { Shirt, Ruler, Shield, Tag } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const ClothingPage = () => {
  const features = [
    {
      icon: Ruler,
      title: 'Detailed Measurements',
      description: 'Accurate sizing information'
    },
    {
      icon: Shield,
      title: 'Quality Check',
      description: 'Verified authentic items'
    },
    {
      icon: Tag,
      title: 'Brand Verification',
      description: 'Authentic brand items only'
    }
  ];

  return <CategoryPage category="Clothing" icon={Shirt} features={features} />;
};

export default ClothingPage;