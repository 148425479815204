import React from 'react';
import { Search, X } from 'lucide-react';
import PostCard from './PostCard';

interface SearchResultsProps {
  query: string;
  results: any[];
  onClose: () => void;
  onPostClick: (post: any) => void;
}

const SearchResults: React.FC<SearchResultsProps> = ({ 
  query, 
  results, 
  onClose,
  onPostClick
}) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-3">
          <Search className="w-6 h-6 text-primary-600" />
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Search Results</h2>
            <p className="text-sm text-gray-500">
              {results.length} {results.length === 1 ? 'result' : 'results'} for "{query}"
            </p>
          </div>
        </div>
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      {results.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {results.map((result) => (
            <PostCard
              key={result.id}
              post={result}
              onPostClick={() => onPostClick(result)}
              showHeart={true}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <Search className="w-12 h-12 text-gray-300 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No results found</h3>
          <p className="text-gray-500">
            Try adjusting your search terms or browse categories instead
          </p>
        </div>
      )}
    </div>
  );
};

export default SearchResults;