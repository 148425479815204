import React, { useState, useRef, useEffect } from 'react';
import { Send, DollarSign, Tag, ChevronDown, X } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { useChatStore } from '../../store/chatStore';
import { AcceptanceStatus, ActionButtons, OfferActions } from './AcceptanceFlow';
import QuickMessages from './QuickMessages';
import SaleCelebration from './SaleCelebration';
import ContactExchange from './ContactExchange';
import OfferModal from './OfferModal';
import toast from 'react-hot-toast';

interface ChatWindowProps {
  chat: any;
  onClose: () => void;
  currentUserId?: string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ chat, onClose, currentUserId }) => {
  const [message, setMessage] = useState('');
  const [showQuickMessages, setShowQuickMessages] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [showContactExchange, setShowContactExchange] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState(true);

  const { 
    addMessage, 
    acceptOffer, 
    declineOffer, 
    makeCounterOffer, 
    markAsSold, 
    updateMeetupDetails 
  } = useChatStore();

  const isSeller = currentUserId === chat.seller.id;

  useEffect(() => {
    if (autoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat.messages, autoScroll]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    setAutoScroll(scrollHeight - scrollTop === clientHeight);
  };

  const handleSendMessage = () => {
    if (!message.trim()) return;

    addMessage(chat.id, {
      sender: currentUserId || '',
      text: message,
      type: 'text'
    });

    setMessage('');
    setShowQuickMessages(false);
  };

  const handleAcceptOffer = async () => {
    try {
      await acceptOffer(chat.id);
      setShowCelebration(true);
      
      addMessage(chat.id, {
        sender: 'system',
        text: chat.lastOffer 
          ? `Offer of $${chat.lastOffer.amount} accepted!` 
          : `Item sold for $${chat.listing.price}!`,
        type: 'system'
      });
    } catch (error) {
      toast.error('Failed to accept offer');
    }
  };

  const handleCelebrationComplete = () => {
    setShowCelebration(false);
    setShowContactExchange(true);
  };

  const handleContactExchange = async (data: { 
    phone?: string; 
    location: string; 
    meetupTime: string;
  }) => {
    try {
      await updateMeetupDetails(chat.id, {
        location: data.location,
        time: data.meetupTime,
        phone: data.phone
      });

      addMessage(chat.id, {
        sender: 'system',
        text: `Meetup arranged at ${data.location} for ${new Date(data.meetupTime).toLocaleString()}`,
        type: 'system'
      });

      setShowContactExchange(false);
      await markAsSold(chat.id);
      
      toast.success('Meetup details set successfully');
    } catch (error) {
      toast.error('Failed to set meetup details');
    }
  };

  const handleMakeOffer = (amount: number) => {
    addMessage(chat.id, {
      sender: currentUserId || '',
      text: `Offered $${amount}`,
      type: 'offer',
      amount
    });
    setShowOfferModal(false);
  };

  return (
    <div className="flex-1 flex flex-col bg-white h-full">
      {/* Header */}
      <div className="border-b flex-shrink-0">
        <div className="p-4 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <img
              src={isSeller ? chat.buyer.avatar : chat.seller.avatar}
              alt="Profile"
              className="w-10 h-10 rounded-full object-cover"
            />
            <div>
              <h3 className="font-medium">
                {isSeller ? chat.buyer.name : chat.seller.name}
              </h3>
              <span className="text-sm text-gray-500">
                {isSeller ? 'Buyer' : 'Seller'}
              </span>
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Listing Preview */}
        <div className="p-4 bg-gray-50">
          <div className="flex gap-4">
            <img
              src={chat.listing.image}
              alt={chat.listing.title}
              className="w-20 h-20 rounded-lg object-cover"
            />
            <div className="flex-1">
              <h4 className="font-medium text-gray-900">{chat.listing.title}</h4>
              <div className="flex items-center gap-4 mt-1">
                <div className="flex items-center gap-1 text-primary-600 font-medium">
                  <DollarSign className="w-4 h-4" />
                  {chat.listing.price}
                </div>
                <div className="flex items-center gap-1 text-gray-500 text-sm">
                  <Tag className="w-4 h-4" />
                  {chat.listing.category}
                </div>
              </div>
              
              {chat.listing.sold ? (
                <AcceptanceStatus meetupDetails={chat.meetupDetails} />
              ) : (
                <ActionButtons
                  isSeller={isSeller}
                  lastOffer={chat.lastOffer}
                  onAccept={handleAcceptOffer}
                  onOffer={() => setShowOfferModal(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Messages */}
      <div 
        className="flex-1 overflow-y-auto p-4 space-y-4"
        onScroll={handleScroll}
      >
        {chat.messages.map((msg: any) => (
          <div
            key={msg.id}
            className={`flex ${msg.sender === currentUserId ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[70%] rounded-lg p-3 ${
                msg.type === 'system'
                  ? 'bg-green-50 text-green-800 mx-auto'
                  : msg.type === 'offer'
                  ? 'bg-blue-50 text-blue-800'
                  : msg.sender === currentUserId
                  ? 'bg-primary-600 text-white'
                  : 'bg-gray-100 text-gray-900'
              }`}
            >
              {msg.type === 'offer' && (
                <div className="flex items-center gap-2 mb-2">
                  <DollarSign className="w-4 h-4" />
                  <span className="font-medium">Offered: ${msg.amount}</span>
                </div>
              )}
              <p className="whitespace-pre-wrap">{msg.text}</p>
              <div className="mt-1 text-xs opacity-75">
                {formatDistanceToNow(new Date(msg.timestamp), { addSuffix: true })}
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Offer Actions */}
      {!chat.listing.sold && chat.lastOffer && isSeller && chat.lastOffer.by === 'buyer' && (
        <OfferActions
          chatId={chat.id}
          lastOffer={chat.lastOffer}
          onAccept={handleAcceptOffer}
          onDecline={declineOffer}
          onCounterOffer={makeCounterOffer}
        />
      )}

      {/* Message Input */}
      <div className="p-4 border-t mt-auto">
        <div className="flex items-end gap-2">
          <div className="flex-1">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message..."
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />
            {showQuickMessages && (
              <div className="absolute bottom-full mb-2 w-full">
                <QuickMessages onSelect={(text) => {
                  setMessage(text);
                  setShowQuickMessages(false);
                }} />
              </div>
            )}
          </div>
          <button
            onClick={() => setShowQuickMessages(!showQuickMessages)}
            className="p-2 text-gray-500 hover:bg-gray-100 rounded-lg"
          >
            <ChevronDown className={`w-5 h-5 transform ${showQuickMessages ? 'rotate-180' : ''}`} />
          </button>
          <button
            onClick={handleSendMessage}
            disabled={!message.trim()}
            className="p-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50"
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Modals */}
      {showCelebration && (
        <SaleCelebration
          amount={chat.lastOffer?.amount || chat.listing.price}
          onClose={handleCelebrationComplete}
        />
      )}

      {showContactExchange && (
        <ContactExchange
          onExchange={handleContactExchange}
          onClose={() => setShowContactExchange(false)}
        />
      )}

      {showOfferModal && (
        <OfferModal
          listingPrice={chat.listing.price}
          onSubmit={handleMakeOffer}
          onClose={() => setShowOfferModal(false)}
        />
      )}
    </div>
  );
};

export default ChatWindow;