import React from 'react';

interface PricingFormProps {
  price: string;
  negotiable: boolean;
  shippingAvailable: boolean;
  onChange: (data: Partial<{
    price: string;
    negotiable: boolean;
    shippingAvailable: boolean;
  }>) => void;
}

function PricingForm({
  price,
  negotiable,
  shippingAvailable,
  onChange,
}: PricingFormProps) {
  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">
          Price
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => onChange({ price: e.target.value })}
            className="w-full pl-7 pr-12 py-2 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500"
            placeholder="0.00"
            min="0"
            step="0.01"
          />
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="negotiable"
            checked={negotiable}
            onChange={(e) => onChange({ negotiable: e.target.checked })}
            className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
          />
          <label htmlFor="negotiable" className="ml-2 block text-sm text-gray-700">
            Price is negotiable
          </label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="shipping"
            checked={shippingAvailable}
            onChange={(e) => onChange({ shippingAvailable: e.target.checked })}
            className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
          />
          <label htmlFor="shipping" className="ml-2 block text-sm text-gray-700">
            Shipping available
          </label>
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded-lg">
        <h4 className="text-sm font-medium text-gray-900 mb-2">Pricing Tips</h4>
        <ul className="text-sm text-gray-600 space-y-1">
          <li>• Research similar items to price competitively</li>
          <li>• Consider item condition when setting price</li>
          <li>• Factor in shipping costs if applicable</li>
          <li>• Be prepared to negotiate if enabled</li>
        </ul>
      </div>
    </div>
  );
}

export default PricingForm;