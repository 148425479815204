import React, { useState } from 'react';
import { Phone, MapPin, Shield, Calendar, Clock, Building, X } from 'lucide-react';
import { usaLocationService } from '../../utils/usaLocationService';
import toast from 'react-hot-toast';

interface ContactExchangeProps {
  onExchange: (data: { 
    phone?: string; 
    location: string; 
    meetupTime: string;
    coordinates?: { lat: number; lng: number } 
  }) => void;
  onClose: () => void;
}

const ContactExchange: React.FC<ContactExchangeProps> = ({ onExchange, onClose }) => {
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [meetupTime, setMeetupTime] = useState('');
  const [showSafeLocations, setShowSafeLocations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const SAFE_LOCATIONS = [
    { name: "Police Station", icon: "🚓", description: "Safe exchange zone" },
    { name: "Shopping Mall", icon: "🏬", description: "Public and monitored" },
    { name: "Coffee Shop", icon: "☕", description: "Casual meetup spot" },
    { name: "Bank", icon: "🏦", description: "Well-lit and secure" },
    { name: "Library", icon: "📚", description: "Public space" }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!location || !meetupTime) {
      toast.error('Please provide both location and meetup time');
      return;
    }

    try {
      setIsLoading(true);
      onExchange({ 
        phone: phone || undefined,
        location,
        meetupTime
      });
    } catch (error) {
      toast.error('Failed to set up meetup');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSafeLocation = async (locationName: string) => {
    try {
      setIsLoading(true);
      const coords = await usaLocationService.getCurrentLocation();
      if (!coords) {
        toast.error('Could not detect your location');
        return;
      }

      const locations = await usaLocationService.searchNearbyPlaces(coords, locationName);
      if (locations.length > 0) {
        setLocation(locations[0].displayName);
      } else {
        toast.error(`No ${locationName} found nearby`);
      }
    } catch (error) {
      toast.error('Failed to find safe locations');
    } finally {
      setIsLoading(false);
    }
  };

  const getMinDateTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 5);
    return now.toISOString().slice(0, 16);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-lg w-full">
        <div className="flex justify-between items-center p-6 border-b">
          <div>
            <h2 className="text-xl font-semibold">Set Up Meetup</h2>
            <p className="text-sm text-gray-500 mt-1">Choose a safe location and time to meet</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Phone Number (Optional) */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              <div className="flex items-center gap-2">
                <Phone className="w-4 h-4" />
                Phone Number (Optional)
              </div>
            </label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="(123) 456-7890"
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
            />
            <p className="text-xs text-gray-500">
              Only shared after both parties agree to meet
            </p>
          </div>

          {/* Meetup Location */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              <div className="flex items-center gap-2">
                <MapPin className="w-4 h-4" />
                Meetup Location
              </div>
            </label>
            <div className="space-y-2">
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Enter meetup location"
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                required
              />
              <button
                type="button"
                onClick={() => setShowSafeLocations(!showSafeLocations)}
                className="text-sm text-primary-600 hover:text-primary-700"
              >
                View suggested safe meetup locations
              </button>
            </div>

            {showSafeLocations && (
              <div className="grid grid-cols-2 gap-3 mt-2">
                {SAFE_LOCATIONS.map((loc) => (
                  <button
                    key={loc.name}
                    type="button"
                    onClick={() => handleSafeLocation(loc.name)}
                    className="flex items-center gap-3 p-3 text-left text-sm bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                  >
                    <span className="text-2xl">{loc.icon}</span>
                    <div>
                      <div className="font-medium">{loc.name}</div>
                      <div className="text-xs text-gray-500">{loc.description}</div>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Meetup Time */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4" />
                Meetup Time
              </div>
            </label>
            <input
              type="datetime-local"
              value={meetupTime}
              onChange={(e) => setMeetupTime(e.target.value)}
              min={getMinDateTime()}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
              required
            />
            <p className="text-xs text-gray-500">
              Meetup time must be at least 5 minutes from now
            </p>
          </div>

          {/* Safety Tips */}
          <div className="bg-yellow-50 rounded-lg p-4">
            <div className="flex items-center gap-2 text-yellow-800 font-medium mb-2">
              <Shield className="w-4 h-4" />
              Safety Tips
            </div>
            <ul className="text-sm text-yellow-700 space-y-2">
              <li className="flex items-center gap-2">
                <Building className="w-4 h-4" />
                Meet in a well-lit, public place
              </li>
              <li className="flex items-center gap-2">
                <Clock className="w-4 h-4" />
                Schedule during daylight hours
              </li>
              <li className="flex items-center gap-2">
                <MapPin className="w-4 h-4" />
                Share your meetup location with a friend
              </li>
              <li className="flex items-center gap-2">
                <Shield className="w-4 h-4" />
                Consider police station safe zones
              </li>
            </ul>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end gap-4 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50"
            >
              {isLoading ? 'Setting up...' : 'Confirm Meetup'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactExchange;