import React, { useState } from 'react';
import { 
  Search, Filter, Eye, Trash2, CheckCircle, 
  AlertTriangle, ExternalLink, DollarSign, Flag
} from 'lucide-react';
import { useListingStore } from '../../store/listingStore';
import toast from 'react-hot-toast';

const ListingManagement: React.FC = () => {
  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState('');
  const { listings, removeListing, approveListing, flagListing } = useListingStore();

  const handleRemoveListing = (listingId: string) => {
    if (window.confirm('Are you sure you want to remove this listing?')) {
      removeListing(listingId);
      toast.success('Listing removed successfully');
    }
  };

  const handleApproveListing = (listingId: string) => {
    approveListing(listingId);
    toast.success('Listing approved successfully');
  };

  const handleFlagListing = (listingId: string, isFlagged: boolean) => {
    flagListing(listingId, !isFlagged);
    toast.success(isFlagged ? 'Listing unflagged' : 'Listing flagged for review');
  };

  const filteredListings = listings.filter(listing => {
    const matchesSearch = listing.title.toLowerCase().includes(search.toLowerCase());
    const matchesFilter = filter === 'all' ||
                         (filter === 'flagged' && listing.flagged) ||
                         (filter === 'pending' && !listing.approved);
    return matchesSearch && matchesFilter;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Listing Management</h2>
        <div className="flex gap-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search listings..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg w-64"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="px-4 py-2 border rounded-lg bg-white"
          >
            <option value="all">All Listings</option>
            <option value="flagged">Flagged</option>
            <option value="pending">Pending Approval</option>
          </select>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Listing
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Seller
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredListings.map((listing) => (
              <tr key={listing.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <img
                      className="h-10 w-10 rounded object-cover"
                      src={listing.images[0]}
                      alt={listing.title}
                    />
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">{listing.title}</div>
                      <div className="text-sm text-gray-500">{listing.category}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    ${listing.price.toLocaleString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    listing.flagged
                      ? 'bg-red-100 text-red-800'
                      : listing.approved
                      ? 'bg-green-100 text-green-800'
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {listing.flagged ? 'Flagged' : listing.approved ? 'Approved' : 'Pending'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <img
                      className="h-8 w-8 rounded-full"
                      src={listing.seller.avatar}
                      alt={listing.seller.name}
                    />
                    <div className="ml-3">
                      <div className="text-sm font-medium text-gray-900">
                        {listing.seller.name}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex items-center gap-2">
                    {!listing.approved && (
                      <button
                        onClick={() => handleApproveListing(listing.id)}
                        className="p-1 text-green-400 hover:text-green-600 rounded-lg"
                        title="Approve Listing"
                      >
                        <CheckCircle className="w-5 h-5" />
                      </button>
                    )}
                    <button
                      onClick={() => handleFlagListing(listing.id, listing.flagged)}
                      className={`p-1 rounded-lg ${
                        listing.flagged
                          ? 'text-gray-400 hover:text-gray-600'
                          : 'text-yellow-400 hover:text-yellow-600'
                      }`}
                      title={listing.flagged ? 'Remove Flag' : 'Flag Listing'}
                    >
                      <Flag className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => handleRemoveListing(listing.id)}
                      className="p-1 text-red-400 hover:text-red-600 rounded-lg"
                      title="Remove Listing"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => window.location.href = `/admin/listings/${listing.id}`}
                      className="p-1 text-gray-400 hover:text-gray-600 rounded-lg"
                      title="View Details"
                    >
                      <ExternalLink className="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListingManagement;