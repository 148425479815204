import React from 'react';
import { Star, ThumbsUp, Clock, MessageCircle, User } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { Review } from '../../store/reviewStore';

interface ReviewsSectionProps {
  reviews: Review[];
  type: 'received' | 'given';
}

const ReviewsSection: React.FC<ReviewsSectionProps> = ({ reviews, type }) => {
  const renderStars = (rating: number) => (
    <div className="flex">
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          className={`w-4 h-4 ${
            star <= rating ? 'text-yellow-400 fill-current' : 'text-gray-300'
          }`}
        />
      ))}
    </div>
  );

  if (reviews.length === 0) {
    return (
      <div className="text-center py-12 bg-gray-50 rounded-lg">
        <MessageCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          No reviews {type === 'received' ? 'received' : 'given'} yet
        </h3>
        <p className="text-gray-500">
          {type === 'received' 
            ? 'Reviews from your buyers will appear here'
            : 'Reviews you give to sellers will appear here'
          }
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {reviews.map((review) => (
        <div key={review.id} className="bg-white rounded-lg p-6 shadow-sm">
          <div className="flex items-start gap-4">
            {review.reviewer.avatar ? (
              <img
                src={review.reviewer.avatar}
                alt={review.reviewer.name}
                className="w-12 h-12 rounded-full object-cover"
              />
            ) : (
              <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
                <User className="w-6 h-6 text-gray-400" />
              </div>
            )}

            <div className="flex-1">
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="font-medium text-gray-900">
                    {review.reviewer.name}
                  </h3>
                  <div className="flex items-center gap-2 mt-1">
                    {renderStars(review.rating)}
                    <span className="text-sm text-gray-500">
                      {formatDistanceToNow(new Date(review.createdAt), {
                        addSuffix: true,
                      })}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-2 flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                <img
                  src={review.listing.image}
                  alt={review.listing.title}
                  className="w-12 h-12 rounded object-cover"
                />
                <div>
                  <div className="font-medium text-gray-900">
                    {review.listing.title}
                  </div>
                  <div className="text-sm text-gray-500">
                    ${review.listing.price}
                  </div>
                </div>
              </div>

              <p className="mt-4 text-gray-700">{review.comment}</p>

              <div className="mt-4 flex items-center gap-4">
                <button
                  className={`flex items-center gap-1 text-sm ${
                    review.isHelpful ? 'text-primary-600' : 'text-gray-500 hover:text-gray-700'
                  }`}
                >
                  <ThumbsUp className="w-4 h-4" />
                  <span>Helpful ({review.helpful})</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewsSection;