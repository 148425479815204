import React, { useState } from 'react';
import { Star, MessageCircle, Heart, Share2, X, Send, MapPin, DollarSign, User, Clock, Tag, Shield } from 'lucide-react';
import { validateOffer } from '../utils/errorHandling';
import { useFavoriteStore } from '../store/favoriteStore';
import { useChatStore } from '../store/chatStore';
import { useAuthStore } from '../store/authStore';
import { useListingStore } from '../store/listingStore';
import SellerProfile from './PostDetail/SellerProfile';
import ShareModal from './PostDetail/ShareModal';
import toast from 'react-hot-toast';

interface PostDetailProps {
  post: any;
  onClose: () => void;
}

const PostDetail: React.FC<PostDetailProps> = ({ post, onClose }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [showOfferInput, setShowOfferInput] = useState(false);
  const [offerAmount, setOfferAmount] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);
  const { user } = useAuthStore();
  const { addFavorite, removeFavorite, isFavorite } = useFavoriteStore();
  const { initiateChat } = useChatStore();
  const { addOffer } = useListingStore();
  const isLiked = isFavorite(post.id);

  const handleBuyNow = () => {
    if (!user) {
      toast.error('Please sign in to make a purchase');
      return;
    }

    try {
      initiateChat({
        listingId: post.id,
        sellerId: post.seller.id,
        buyerId: user.id,
        type: 'direct',
        amount: post.price
      });
      toast.success('Chat initiated with seller');
      onClose();
    } catch (error) {
      toast.error('Failed to initiate chat');
    }
  };

  const handleMakeOffer = () => {
    if (!user) {
      toast.error('Please sign in to make an offer');
      return;
    }

    if (!offerAmount || isNaN(Number(offerAmount))) {
      toast.error('Please enter a valid amount');
      return;
    }

    try {
      const amount = Number(offerAmount);
      validateOffer(offerAmount, post.price);
      
      addOffer(post.id, amount, user.id);
      initiateChat({
        listingId: post.id,
        sellerId: post.seller.id,
        buyerId: user.id,
        type: 'offer',
        amount
      });

      setShowOfferInput(false);
      onClose();
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to send offer');
    }
  };

  const handleLike = (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      if (isLiked) {
        removeFavorite(post.id);
        toast.success('Removed from favorites');
      } else {
        addFavorite({
          ...post,
          status: 'available'
        });
        toast.success('Added to favorites');
      }
    } catch (error) {
      console.error('Error handling favorite:', error);
      toast.error('Failed to update favorites');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-0 md:p-4 overflow-y-auto">
      <div className="bg-white w-full h-full md:h-auto md:max-h-[90vh] md:w-auto md:max-w-4xl md:rounded-lg overflow-auto">
        {/* Header */}
        <div className="sticky top-0 z-10 flex justify-between items-center p-4 bg-white border-b">
          <h2 className="text-lg md:text-xl font-semibold truncate">{post.title}</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-5 h-5 md:w-6 md:h-6" />
          </button>
        </div>

        <div className="flex flex-col md:flex-row md:gap-6 p-4">
          {/* Image Gallery */}
          <div className="w-full md:w-1/2 space-y-4">
            <div className="aspect-square rounded-lg overflow-hidden bg-gray-100">
              <img
                src={post.images[selectedImage]}
                alt={post.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="grid grid-cols-4 gap-2">
              {post.images.map((image: string, index: number) => (
                <button
                  key={index}
                  onClick={() => setSelectedImage(index)}
                  className={`aspect-square rounded-lg overflow-hidden ${
                    selectedImage === index ? 'ring-2 ring-primary-600' : ''
                  }`}
                >
                  <img
                    src={image}
                    alt={`${post.title} ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          </div>

          {/* Details */}
          <div className="w-full md:w-1/2 mt-6 md:mt-0 space-y-6">
            {/* Price and Actions */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-2xl md:text-3xl font-bold text-gray-900">
                    ${post.price.toLocaleString()}
                  </p>
                  <div className="flex items-center gap-2 mt-1 text-gray-500">
                    <Clock className="w-4 h-4" />
                    <span>Listed 2 days ago</span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={handleLike}
                    className={`p-2 rounded-full ${
                      isLiked
                        ? 'bg-red-50 text-red-500'
                        : 'hover:bg-gray-100 text-gray-600'
                    }`}
                  >
                    <Heart className={isLiked ? 'w-5 h-5 fill-current' : 'w-5 h-5'} />
                  </button>
                  <button
                    onClick={() => setShowShareModal(true)}
                    className="p-2 hover:bg-gray-100 rounded-full text-gray-600"
                  >
                    <Share2 className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {/* Buy/Offer Buttons */}
              <div className="flex gap-3">
                <button
                  onClick={handleBuyNow}
                  className="flex-1 bg-primary-600 text-white px-4 py-3 rounded-lg hover:bg-primary-700 flex items-center justify-center gap-2 text-sm md:text-base"
                >
                  <DollarSign className="w-5 h-5" />
                  Buy Now
                </button>
                {showOfferInput ? (
                  <div className="flex-1 flex gap-2">
                    <div className="relative flex-1">
                      <span className="absolute left-3 top-3 text-gray-500">$</span>
                      <input
                        type="number"
                        value={offerAmount}
                        onChange={(e) => setOfferAmount(e.target.value)}
                        className="w-full pl-8 pr-4 py-2 border rounded-lg"
                        placeholder="Enter amount"
                      />
                    </div>
                    <button
                      onClick={handleMakeOffer}
                      className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
                    >
                      <Send className="w-5 h-5" />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => setShowOfferInput(true)}
                    className="flex-1 border border-primary-600 text-primary-600 px-4 py-3 rounded-lg hover:bg-primary-50 flex items-center justify-center gap-2 text-sm md:text-base"
                  >
                    <MessageCircle className="w-5 h-5" />
                    Make Offer
                  </button>
                )}
              </div>
            </div>

            {/* Seller Info */}
            <SellerProfile seller={post.seller} onMessageClick={handleBuyNow} />

            {/* Item Details */}
            <div className="space-y-4">
              <div className="flex items-center gap-2 text-gray-600">
                <MapPin className="w-5 h-5" />
                <span>{post.location}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <Tag className="w-5 h-5" />
                <span>{post.category}</span>
              </div>
              <p className="text-gray-600 whitespace-pre-wrap text-sm md:text-base">{post.description}</p>
            </div>

            {/* Safety Tips */}
            <div className="bg-yellow-50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-yellow-800 font-medium mb-2">
                <Shield className="w-5 h-5" />
                Safety Tips
              </div>
              <ul className="text-sm text-yellow-700 space-y-1">
                <li>• Meet in a safe, public place</li>
                <li>• Don't pay in advance</li>
                <li>• Check the item before paying</li>
                <li>• Bring someone with you</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {showShareModal && (
        <ShareModal listing={post} onClose={() => setShowShareModal(false)} />
      )}
    </div>
  );
};

export default PostDetail;