import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import { getPosts } from '../utils/posts';
import { storage } from '../lib/storage';
import toast from 'react-hot-toast';

export interface Listing {
  id: string;
  userId: string;
  title: string;
  price: number;
  description: string;
  images: string[];
  location: string;
  category: string;
  rating: number;
  reviews: number;
  views: number;
  comments: number;
  createdAt: string;
  sold?: boolean;
  buyerId?: string;
  recentOffer?: {
    amount: number;
    by: 'buyer' | 'seller';
    timestamp: string;
  };
  seller: {
    id: string;
    name: string;
    rating: number;
    reviews: number;
    avatar: string;
  };
}

interface ListingStore {
  listings: Listing[];
  version: number;
  initializeListings: () => void;
  addListing: (listing: Omit<Listing, 'id' | 'createdAt'>) => void;
  updateListing: (id: string, updates: Partial<Listing>) => void;
  removeListing: (id: string) => void;
  markAsSold: (id: string, buyerId: string) => void;
  addOffer: (listingId: string, amount: number, buyerId: string) => void;
  getListingsByCategory: (category: string) => Listing[];
  getListingsByUser: (userId: string) => Listing[];
  getSoldListingsByUser: (userId: string) => Listing[];
  getActiveListingsByUser: (userId: string) => Listing[];
  getPurchasesByUser: (userId: string) => Listing[];
  getAllListings: () => Listing[];
}

export const useListingStore = create<ListingStore>()(
  persist(
    (set, get) => ({
      listings: [],
      version: 1,

      initializeListings: () => {
        const currentListings = get().listings;
        if (currentListings.length === 0) {
          const initialListings = getPosts();
          set({ listings: initialListings });
        }
      },
      
      addListing: (listing) => {
        const newListing = {
          ...listing,
          id: uuidv4(),
          createdAt: new Date().toISOString(),
          rating: 0,
          reviews: 0,
          views: 0,
          comments: 0,
          sold: false
        };
        
        set((state) => ({
          listings: [newListing, ...state.listings]
        }));
      },

      updateListing: (id, updates) => {
        set((state) => ({
          listings: state.listings.map(listing =>
            listing.id === id ? { ...listing, ...updates } : listing
          )
        }));
      },

      removeListing: (id) => {
        set((state) => ({
          listings: state.listings.filter(listing => listing.id !== id)
        }));
      },

      markAsSold: (id, buyerId) => {
        set((state) => ({
          listings: state.listings.map(listing =>
            listing.id === id ? { ...listing, sold: true, buyerId } : listing
          )
        }));
        toast.success('Item marked as sold!');
      },

      addOffer: (listingId, amount, buyerId) => {
        set((state) => ({
          listings: state.listings.map(listing =>
            listing.id === listingId
              ? {
                  ...listing,
                  recentOffer: {
                    amount,
                    by: 'buyer',
                    timestamp: new Date().toISOString()
                  }
                }
              : listing
          )
        }));
      },

      getListingsByCategory: (category) => {
        return get().listings.filter(listing => 
          listing.category === category && !listing.sold
        );
      },

      getListingsByUser: (userId) => {
        return get().listings.filter(listing => listing.userId === userId);
      },

      getSoldListingsByUser: (userId) => {
        return get().listings.filter(listing => 
          listing.userId === userId && listing.sold === true
        );
      },

      getActiveListingsByUser: (userId) => {
        return get().listings.filter(listing => 
          listing.userId === userId && !listing.sold
        );
      },

      getPurchasesByUser: (userId) => {
        return get().listings.filter(listing => listing.buyerId === userId);
      },

      getAllListings: () => {
        return get().listings.filter(listing => !listing.sold);
      },
    }),
    {
      name: 'listings-storage',
      version: 1
    }
  )
);

useListingStore.getState().initializeListings();