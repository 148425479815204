import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import toast from 'react-hot-toast';

interface SavedItem {
  id: string;
  title: string;
  price: number;
  description: string;
  images: string[];
  location: string;
  category: string;
  status: 'available' | 'sold';
  seller: {
    id: string;
    name: string;
    rating: number;
    avatar: string;
  };
  savedAt: string;
}

interface FavoriteStore {
  version: number;
  favorites: SavedItem[];
  isInSavedItemsView: boolean;
  setInSavedItemsView: (value: boolean) => void;
  addFavorite: (listing: Omit<SavedItem, 'savedAt'>) => boolean;
  removeFavorite: (listingId: string) => void;
  isFavorite: (listingId: string) => boolean;
  getFavorites: () => SavedItem[];
}

export const useFavoriteStore = create<FavoriteStore>()(
  persist(
    (set, get) => ({
      version: 1,
      favorites: [],
      isInSavedItemsView: false,

      setInSavedItemsView: (value: boolean) => {
        set({ isInSavedItemsView: value });
      },

      addFavorite: (listing) => {
        let success = false;
        set((state) => {
          if (state.favorites.some(item => item.id === listing.id)) {
            success = false;
            return state;
          }

          const newItem: SavedItem = {
            ...listing,
            savedAt: new Date().toISOString()
          };

          success = true;
          return {
            favorites: [newItem, ...state.favorites]
          };
        });
        return success;
      },

      removeFavorite: (listingId) => {
        set((state) => ({
          favorites: state.favorites.filter(item => item.id !== listingId)
        }));
      },

      isFavorite: (listingId) => {
        return get().favorites.some(item => item.id === listingId);
      },

      getFavorites: () => {
        const state = get();
        if (!state.isInSavedItemsView) {
          console.warn('Attempted to access favorites outside SavedItems view');
          return [];
        }
        return state.favorites;
      }
    }),
    {
      name: 'favorites-storage',
      storage: createJSONStorage(() => localStorage),
      version: 1,
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            version: 1,
            favorites: persistedState.favorites || [],
            isInSavedItemsView: false
          };
        }
        return persistedState as FavoriteStore;
      }
    }
  )
);