import React from 'react';
import { HomeIcon, Flower2, Shield, Truck } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const HomeGardenPage = () => {
  const features = [
    {
      icon: Flower2,
      title: 'Seasonal Items',
      description: 'Fresh and seasonal products'
    },
    {
      icon: Shield,
      title: 'Quality Assured',
      description: 'All items inspected'
    },
    {
      icon: Truck,
      title: 'Local Delivery',
      description: 'Available for large items'
    }
  ];

  return <CategoryPage category="Home & Garden" icon={HomeIcon} features={features} />;
};

export default HomeGardenPage;