import React from 'react';
import { Footprints, Shield, Tag, Package } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const ShoesPage = () => {
  const features = [
    {
      icon: Shield,
      title: 'Authenticity Check',
      description: 'Verified authentic pairs'
    },
    {
      icon: Tag,
      title: 'Brand Verification',
      description: 'Genuine products only'
    },
    {
      icon: Package,
      title: 'Original Box',
      description: 'Complete packaging'
    }
  ];

  return <CategoryPage category="Shoes" icon={Footprints} features={features} />;
};

export default ShoesPage;