import React from 'react';
import { Check, MapPin, Calendar } from 'lucide-react';

interface AcceptanceStatusProps {
  meetupDetails?: {
    location: string;
    time: string;
  };
}

const AcceptanceStatus: React.FC<AcceptanceStatusProps> = ({ meetupDetails }) => {
  return (
    <div className="mt-2 space-y-2">
      <span className="inline-flex items-center px-2 py-0.5 bg-green-100 text-green-800 rounded-full text-xs font-medium">
        <Check className="w-3 h-3 mr-1" />
        Sold
      </span>
      {meetupDetails && (
        <div className="text-sm text-gray-600">
          <div className="flex items-center gap-1">
            <MapPin className="w-4 h-4" />
            {meetupDetails.location}
          </div>
          <div className="flex items-center gap-1">
            <Calendar className="w-4 h-4" />
            {new Date(meetupDetails.time).toLocaleString()}
          </div>
        </div>
      )}
    </div>
  );
};

export default AcceptanceStatus;