import React, { useState } from 'react';
import { DollarSign, X } from 'lucide-react';
import toast from 'react-hot-toast';

interface OfferModalProps {
  listingPrice: number;
  onSubmit: (amount: number) => void;
  onClose: () => void;
}

const OfferModal: React.FC<OfferModalProps> = ({
  listingPrice,
  onSubmit,
  onClose
}) => {
  const [amount, setAmount] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const offerAmount = Number(amount);

    if (!offerAmount || offerAmount <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }

    if (offerAmount >= listingPrice) {
      toast.error('Offer should be less than listing price');
      return;
    }

    onSubmit(offerAmount);
  };

  const suggestedOffers = [
    Math.round(listingPrice * 0.9),
    Math.round(listingPrice * 0.85),
    Math.round(listingPrice * 0.8)
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Make an Offer</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Listing Price: ${listingPrice}
            </label>
            <div className="relative">
              <span className="absolute left-3 top-2 text-gray-500">$</span>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full pl-8 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                placeholder="Enter your offer"
                step="0.01"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Suggested Offers
            </label>
            <div className="flex gap-2">
              {suggestedOffers.map((offer) => (
                <button
                  key={offer}
                  type="button"
                  onClick={() => setAmount(offer.toString())}
                  className="px-3 py-1 bg-gray-100 rounded-lg text-sm hover:bg-gray-200"
                >
                  ${offer}
                </button>
              ))}
            </div>
          </div>

          <div className="bg-yellow-50 rounded-lg p-4 text-sm text-yellow-800">
            <ul className="space-y-1">
              <li>• Make a reasonable offer</li>
              <li>• Consider item condition</li>
              <li>• Be prepared to negotiate</li>
            </ul>
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 flex items-center gap-2"
            >
              <DollarSign className="w-5 h-5" />
              Make Offer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OfferModal;