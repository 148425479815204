import React, { useState, useEffect } from 'react';
import { MapPin, Loader } from 'lucide-react';
import toast from 'react-hot-toast';

interface LocationFormProps {
  location: string;
  meetupPreference: string;
  onChange: (data: Partial<{
    location: string;
    meetupPreference: string;
  }>) => void;
}

const meetupOptions = [
  'Public place only',
  'Porch pickup available',
  'Home pickup available',
  'Shipping only'
];

function LocationForm({ location, meetupPreference, onChange }: LocationFormProps) {
  const [isDetectingLocation, setIsDetectingLocation] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    // Try to get user's location when component mounts
    if (!location) {
      detectLocation();
    }
  }, []);

  const detectLocation = async () => {
    setIsDetectingLocation(true);
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      });

      const { latitude, longitude } = position.coords;
      
      // Use reverse geocoding to get city name
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const data = await response.json();
      
      // Extract city and state/country
      const city = data.address.city || data.address.town || data.address.village;
      const state = data.address.state || data.address.country;
      
      if (city && state) {
        const locationString = `${city}, ${state}`;
        onChange({ location: locationString });
        toast.success('Location detected successfully');
      } else {
        throw new Error('Could not determine city');
      }
    } catch (error) {
      console.error('Error detecting location:', error);
      toast.error('Could not detect location. Please enter manually.');
    } finally {
      setIsDetectingLocation(false);
    }
  };

  const handleLocationInput = async (value: string) => {
    onChange({ location: value });

    if (value.length < 3) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}&limit=5`
      );
      const data = await response.json();
      
      const newSuggestions = data
        .map((item: any) => {
          const parts = item.display_name.split(', ');
          return `${parts[0]}, ${parts[parts.length - 2] || parts[parts.length - 1]}`;
        })
        .filter((item: string, index: number, self: string[]) => 
          self.indexOf(item) === index
        );

      setSuggestions(newSuggestions);
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Location
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MapPin className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={location}
            onChange={(e) => handleLocationInput(e.target.value)}
            className="w-full pl-10 pr-24 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500"
            placeholder="Enter your location"
          />
          <button
            type="button"
            onClick={detectLocation}
            disabled={isDetectingLocation}
            className="absolute right-2 top-2 px-3 py-1 bg-primary-50 text-primary-600 rounded-md hover:bg-primary-100 transition-colors flex items-center gap-1"
          >
            {isDetectingLocation ? (
              <>
                <Loader className="w-4 h-4 animate-spin" />
                <span>Detecting...</span>
              </>
            ) : (
              <>
                <MapPin className="w-4 h-4" />
                <span>Detect</span>
              </>
            )}
          </button>
        </div>
        {suggestions.length > 0 && (
          <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200">
            {suggestions.map((suggestion, index) => (
              <button
                key={index}
                onClick={() => {
                  onChange({ location: suggestion });
                  setSuggestions([]);
                }}
                className="w-full text-left px-4 py-2 hover:bg-gray-50 first:rounded-t-md last:rounded-b-md"
              >
                <div className="flex items-center gap-2">
                  <MapPin className="w-4 h-4 text-gray-400" />
                  <span>{suggestion}</span>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Meetup Preference
        </label>
        <div className="space-y-3">
          {meetupOptions.map((option) => (
            <button
              key={option}
              type="button"
              onClick={() => onChange({ meetupPreference: option })}
              className={`w-full px-4 py-3 rounded-lg border ${
                meetupPreference === option
                  ? 'border-primary-600 bg-primary-50 text-primary-700'
                  : 'border-gray-300 text-gray-700 hover:border-primary-600'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      <div className="bg-yellow-50 p-4 rounded-lg">
        <h4 className="text-sm font-medium text-yellow-800 mb-2">Safety Tips</h4>
        <ul className="text-sm text-yellow-700 space-y-1">
          <li>• Meet in well-lit, public places</li>
          <li>• Bring someone with you</li>
          <li>• Trust your instincts</li>
          <li>• Verify buyer/seller information</li>
        </ul>
      </div>
    </div>
  );
}

export default LocationForm;