import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { DollarSign, Check, Star, Trash2, Pin } from 'lucide-react';

interface MessageListProps {
  chats: any[];
  selectedChatId: string | null;
  onSelectChat: (chatId: string) => void;
  currentUserId: string | undefined;
  onDeleteChat: (chatId: string) => void;
  onTogglePin: (chatId: string) => void;
  onToggleImportant: (chatId: string) => void;
}

const MessageList: React.FC<MessageListProps> = ({
  chats,
  selectedChatId,
  onSelectChat,
  currentUserId,
  onDeleteChat,
  onTogglePin,
  onToggleImportant
}) => {
  const sortedChats = [...chats].sort((a, b) => {
    if (a.pinned && !b.pinned) return -1;
    if (!a.pinned && b.pinned) return 1;
    return new Date(b.lastMessage?.timestamp || b.createdAt).getTime() -
           new Date(a.lastMessage?.timestamp || a.createdAt).getTime();
  });

  return (
    <div className="flex-1 overflow-y-auto">
      {sortedChats.map((chat) => {
        const isSeller = currentUserId === chat.seller.id;
        const otherUser = isSeller ? chat.buyer : chat.seller;
        const lastMessage = chat.messages[chat.messages.length - 1];
        
        return (
          <div
            key={chat.id}
            className={`group relative p-4 flex items-start gap-3 hover:bg-gray-100 transition-colors cursor-pointer ${
              selectedChatId === chat.id ? 'bg-gray-100' : ''
            } ${chat.pinned ? 'bg-yellow-50' : ''}`}
            onClick={() => onSelectChat(chat.id)}
          >
            <img
              src={otherUser.avatar}
              alt={otherUser.name}
              className="w-12 h-12 rounded-full object-cover flex-shrink-0"
            />
            
            <div className="flex-1 min-w-0">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <h3 className="font-medium text-gray-900">{otherUser.name}</h3>
                  {chat.pinned && (
                    <Pin className="w-4 h-4 text-yellow-500" />
                  )}
                  {chat.important && (
                    <Star className="w-4 h-4 text-yellow-500 fill-current" />
                  )}
                </div>
              </div>

              <div className="mt-1">
                <div className="flex items-center gap-2">
                  <p className="text-sm text-gray-600 truncate">
                    {chat.listing.title}
                  </p>
                  {chat.listing.sold && (
                    <span className="flex items-center gap-1 text-xs text-green-600 font-medium bg-green-50 px-1.5 py-0.5 rounded-full">
                      <Check className="w-3 h-3" />
                      Sold
                    </span>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-4 mt-1">
                {chat.lastOffer && (
                  <div className="flex items-center gap-1 text-sm">
                    <DollarSign className="w-4 h-4 text-primary-600" />
                    <span className="font-medium">${chat.lastOffer.amount}</span>
                  </div>
                )}

                {chat.rating && (
                  <div className="flex items-center gap-1 text-sm text-yellow-500">
                    <Star className="w-4 h-4" />
                    {chat.rating}
                  </div>
                )}
              </div>

              <div className="mt-2 text-xs text-gray-500">
                {formatDistanceToNow(new Date(lastMessage?.timestamp || chat.createdAt), { 
                  addSuffix: true 
                })}
              </div>
            </div>

            <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-1">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleImportant(chat.id);
                }}
                className={`p-1.5 rounded-full ${
                  chat.important
                    ? 'bg-yellow-100 text-yellow-600'
                    : 'bg-gray-100 text-gray-600'
                } hover:bg-gray-200`}
              >
                <Star className={chat.important ? 'w-4 h-4 fill-current' : 'w-4 h-4'} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onTogglePin(chat.id);
                }}
                className={`p-1.5 rounded-full ${
                  chat.pinned
                    ? 'bg-yellow-100 text-yellow-600'
                    : 'bg-gray-100 text-gray-600'
                } hover:bg-gray-200`}
              >
                <Pin className="w-4 h-4" />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (window.confirm('Are you sure you want to delete this conversation?')) {
                    onDeleteChat(chat.id);
                  }
                }}
                className="p-1.5 rounded-full bg-gray-100 text-gray-600 hover:bg-red-100 hover:text-red-600"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageList;