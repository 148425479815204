import React from 'react';
import { 
  TrendingUp, Users, Package, AlertTriangle, DollarSign,
  MessageSquare, ShoppingBag, UserCheck, Clock
} from 'lucide-react';

interface StatsCardProps {
  title: string;
  value: string | number;
  change?: number;
  icon: React.FC<{ className?: string }>;
  color: string;
}

const StatsCard: React.FC<StatsCardProps> = ({ title, value, change, icon: Icon, color }) => (
  <div className="bg-white rounded-xl shadow-sm p-6">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-600 mb-1">{title}</p>
        <h3 className="text-2xl font-bold">{value}</h3>
        {change !== undefined && (
          <p className={`text-sm mt-2 ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {change >= 0 ? '+' : ''}{change}% from last month
          </p>
        )}
      </div>
      <div className={`p-4 rounded-full ${color}`}>
        <Icon className="w-6 h-6" />
      </div>
    </div>
  </div>
);

interface AdminStatsProps {
  stats: {
    totalUsers: number;
    activeUsers: number;
    totalListings: number;
    activeListings: number;
    totalRevenue: number;
    pendingReports: number;
    averageResponseTime: number;
    completionRate: number;
    userGrowth: number;
    listingGrowth: number;
    revenueGrowth: number;
  };
}

const AdminStats: React.FC<AdminStatsProps> = ({ stats }) => {
  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold mb-6">Dashboard Overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          <StatsCard
            title="Total Users"
            value={stats.totalUsers.toLocaleString()}
            change={stats.userGrowth}
            icon={Users}
            color="bg-blue-50 text-blue-600"
          />
          <StatsCard
            title="Active Listings"
            value={stats.activeListings.toLocaleString()}
            change={stats.listingGrowth}
            icon={Package}
            color="bg-green-50 text-green-600"
          />
          <StatsCard
            title="Total Revenue"
            value={`$${stats.totalRevenue.toLocaleString()}`}
            change={stats.revenueGrowth}
            icon={DollarSign}
            color="bg-purple-50 text-purple-600"
          />
          <StatsCard
            title="Pending Reports"
            value={stats.pendingReports}
            icon={AlertTriangle}
            color="bg-red-50 text-red-600"
          />
          <StatsCard
            title="Active Users"
            value={`${stats.activeUsers.toLocaleString()}`}
            icon={UserCheck}
            color="bg-indigo-50 text-indigo-600"
          />
          <StatsCard
            title="Response Time"
            value={`${stats.averageResponseTime}m`}
            icon={Clock}
            color="bg-yellow-50 text-yellow-600"
          />
          <StatsCard
            title="Completion Rate"
            value={`${stats.completionRate}%`}
            icon={TrendingUp}
            color="bg-emerald-50 text-emerald-600"
          />
          <StatsCard
            title="Messages"
            value="2.4k"
            icon={MessageSquare}
            color="bg-pink-50 text-pink-600"
          />
        </div>
      </div>

      {/* Charts and additional stats can be added here */}
    </div>
  );
};

export default AdminStats;