import React from 'react';
import { Bike, Shield, Activity, MapPin } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const SportsPage = () => {
  const features = [
    {
      icon: Activity,
      title: 'Equipment Testing',
      description: 'Try before you buy'
    },
    {
      icon: Shield,
      title: 'Safety Verified',
      description: 'Equipment safety checked'
    },
    {
      icon: MapPin,
      title: 'Local Meetups',
      description: 'Safe meeting locations'
    }
  ];

  return <CategoryPage category="Sports" icon={Bike} features={features} />;
};

export default SportsPage;