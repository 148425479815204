import React from 'react';
import { Sofa, Shield, Package, Truck } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const FurniturePage = () => {
  const features = [
    {
      icon: Shield,
      title: 'Quality Guaranteed',
      description: 'All items inspected and verified'
    },
    {
      icon: Package,
      title: 'Local Pickup',
      description: 'Safe and secure meetup locations'
    },
    {
      icon: Truck,
      title: 'Delivery Available',
      description: 'Optional delivery service'
    }
  ];

  return <CategoryPage category="Furniture" icon={Sofa} features={features} />;
};

export default FurniturePage;