import React from 'react';
import { Watch, Shield, Sparkles, Gem } from 'lucide-react';
import CategoryPage from '../../components/CategoryPage';

const WatchesPage = () => {
  const features = [
    {
      icon: Shield,
      title: 'Authentication',
      description: 'Expert verification'
    },
    {
      icon: Sparkles,
      title: 'Condition Check',
      description: 'Detailed inspection'
    },
    {
      icon: Gem,
      title: 'Luxury Service',
      description: 'Premium experience'
    }
  ];

  return <CategoryPage category="Watches" icon={Watch} features={features} />;
};

export default WatchesPage;