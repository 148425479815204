import React from 'react';
import { Star, MessageCircle, Shield, Clock, MapPin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface SellerProfileProps {
  seller: {
    id: string;
    name: string;
    avatar: string;
    rating: number;
    reviews: number;
    joinDate?: string;
    location?: string;
    responseTime?: string;
    verificationLevel?: string;
  };
  onMessageClick?: () => void;
}

const SellerProfile: React.FC<SellerProfileProps> = ({ seller, onMessageClick }) => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(`/profile/${seller.id}`);
  };

  return (
    <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
      <img
        src={seller.avatar}
        alt={seller.name}
        className="w-12 h-12 rounded-full object-cover cursor-pointer"
        onClick={handleProfileClick}
      />
      <div className="flex-1">
        <h3 
          className="font-medium text-gray-900 hover:text-primary-600 cursor-pointer"
          onClick={handleProfileClick}
        >
          {seller.name}
        </h3>
        <div className="flex items-center gap-4 text-sm text-gray-500">
          <div className="flex items-center gap-1">
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
            <span>{seller.rating}</span>
          </div>
          <span>•</span>
          <span>{seller.reviews} reviews</span>
        </div>
        {(seller.joinDate || seller.location || seller.responseTime) && (
          <div className="mt-2 grid grid-cols-2 gap-2 text-sm text-gray-500">
            {seller.joinDate && (
              <div className="flex items-center gap-1">
                <Clock className="w-4 h-4" />
                <span>Member since {new Date(seller.joinDate).getFullYear()}</span>
              </div>
            )}
            {seller.location && (
              <div className="flex items-center gap-1">
                <MapPin className="w-4 h-4" />
                <span>{seller.location}</span>
              </div>
            )}
            {seller.responseTime && (
              <div className="flex items-center gap-1">
                <MessageCircle className="w-4 h-4" />
                <span>Responds {seller.responseTime}</span>
              </div>
            )}
            {seller.verificationLevel && (
              <div className="flex items-center gap-1">
                <Shield className="w-4 h-4" />
                <span>{seller.verificationLevel}</span>
              </div>
            )}
          </div>
        )}
      </div>
      {onMessageClick && (
        <button
          onClick={onMessageClick}
          className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
        >
          Message
        </button>
      )}
    </div>
  );
};

export default SellerProfile;