import React, { useState } from 'react';
import { useAdminStore } from '../../store/adminStore';
import { 
  BarChart, Users, Package, AlertTriangle, LogOut, 
  DollarSign, Settings, Shield, Search, Filter,
  MessageSquare, Flag, Tag, Trash2
} from 'lucide-react';
import AdminLogin from './AdminLogin';
import AdminStats from './AdminStats';
import UserManagement from './UserManagement';
import ListingManagement from './ListingManagement';
import ReportManagement from './ReportManagement';
import CategoryManagement from './CategoryManagement';
import MessageManagement from './MessageManagement';
import SettingsPanel from './SettingsPanel';
import toast from 'react-hot-toast';

type TabType = 'overview' | 'users' | 'listings' | 'reports' | 'categories' | 'messages' | 'settings';

const AdminDashboard: React.FC = () => {
  const { isAdmin, getStats, signOut } = useAdminStore();
  const [activeTab, setActiveTab] = useState<TabType>('overview');
  const stats = getStats();

  if (!isAdmin) {
    return <AdminLogin onSuccess={() => toast.success('Welcome to Admin Dashboard')} />;
  }

  const handleSignOut = () => {
    signOut();
    window.location.href = '/';
  };

  const tabs = [
    { id: 'overview', label: 'Overview', icon: BarChart },
    { id: 'users', label: 'Users', icon: Users },
    { id: 'listings', label: 'Listings', icon: Package },
    { id: 'reports', label: 'Reports', icon: Flag },
    { id: 'categories', label: 'Categories', icon: Tag },
    { id: 'messages', label: 'Messages', icon: MessageSquare },
    { id: 'settings', label: 'Settings', icon: Settings }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white border-r">
        <div className="flex items-center justify-between p-4 border-b">
          <div className="flex items-center gap-2">
            <Shield className="w-6 h-6 text-primary-600" />
            <span className="text-xl font-bold">Admin Panel</span>
          </div>
        </div>

        <nav className="p-4 space-y-2">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id as TabType)}
              className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-colors ${
                activeTab === tab.id
                  ? 'bg-primary-50 text-primary-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <tab.icon className="w-5 h-5" />
              {tab.label}
            </button>
          ))}

          <button
            onClick={handleSignOut}
            className="w-full flex items-center gap-3 px-4 py-3 rounded-lg text-red-600 hover:bg-red-50 transition-colors"
          >
            <LogOut className="w-5 h-5" />
            Sign Out
          </button>
        </nav>
      </div>

      {/* Main Content */}
      <div className="ml-64 p-8">
        {activeTab === 'overview' && <AdminStats stats={stats} />}
        {activeTab === 'users' && <UserManagement />}
        {activeTab === 'listings' && <ListingManagement />}
        {activeTab === 'reports' && <ReportManagement />}
        {activeTab === 'categories' && <CategoryManagement />}
        {activeTab === 'messages' && <MessageManagement />}
        {activeTab === 'settings' && <SettingsPanel />}
      </div>
    </div>
  );
};

export default AdminDashboard;