import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';

export interface Review {
  id: string;
  transactionId: string;
  listingId: string;
  reviewerId: string;
  revieweeId: string;
  rating: number;
  comment: string;
  createdAt: string;
  type: 'buyer' | 'seller';
  listing: {
    id: string;
    title: string;
    price: number;
    image: string;
  };
  reviewer: {
    id: string;
    name: string;
    avatar?: string;
  };
  helpful: number;
  isHelpful?: boolean;
}

interface ReviewStats {
  averageRating: number;
  totalReviews: number;
  buyerReviews: number;
  sellerReviews: number;
  ratings: {
    5: number;
    4: number;
    3: number;
    2: number;
    1: number;
  };
}

interface ReviewState {
  reviews: Review[];
  addReview: (review: Omit<Review, 'id' | 'createdAt' | 'helpful' | 'isHelpful'>) => void;
  getReviewsForUser: (userId: string, type: 'received' | 'given') => Review[];
  getReviewStats: (userId: string) => ReviewStats;
  markHelpful: (reviewId: string) => void;
  hasReviewed: (transactionId: string, userId: string) => boolean;
  getAverageRating: (userId: string) => number;
  getReviewCount: (userId: string) => number;
  getRecentReviews: (userId: string, limit?: number) => Review[];
  getBuyerReviews: (userId: string) => Review[];
  getSellerReviews: (userId: string) => Review[];
}

export const useReviewStore = create<ReviewState>()(
  persist(
    (set, get) => ({
      reviews: [],

      addReview: (reviewData) => {
        const newReview: Review = {
          ...reviewData,
          id: uuidv4(),
          createdAt: new Date().toISOString(),
          helpful: 0,
          isHelpful: false
        };

        set(state => ({
          reviews: [newReview, ...state.reviews]
        }));

        toast.success('Review submitted successfully');
      },

      getReviewsForUser: (userId, type) => {
        const { reviews } = get();
        return reviews.filter(review => 
          type === 'received' 
            ? review.revieweeId === userId 
            : review.reviewerId === userId
        ).sort((a, b) => 
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      },

      getReviewStats: (userId) => {
        const reviews = get().getReviewsForUser(userId, 'received');
        const ratings = reviews.reduce((acc, review) => {
          acc[review.rating as keyof typeof acc]++;
          return acc;
        }, { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 });

        const buyerReviews = reviews.filter(r => r.type === 'buyer').length;
        const sellerReviews = reviews.filter(r => r.type === 'seller').length;

        return {
          averageRating: reviews.reduce((acc, r) => acc + r.rating, 0) / reviews.length || 0,
          totalReviews: reviews.length,
          buyerReviews,
          sellerReviews,
          ratings
        };
      },

      markHelpful: (reviewId) => {
        set(state => ({
          reviews: state.reviews.map(review =>
            review.id === reviewId
              ? {
                  ...review,
                  helpful: review.isHelpful ? review.helpful - 1 : review.helpful + 1,
                  isHelpful: !review.isHelpful
                }
              : review
          )
        }));
      },

      hasReviewed: (transactionId, userId) => {
        return get().reviews.some(
          review => review.transactionId === transactionId && review.reviewerId === userId
        );
      },

      getAverageRating: (userId) => {
        const reviews = get().getReviewsForUser(userId, 'received');
        if (reviews.length === 0) return 0;
        
        const sum = reviews.reduce((acc, review) => acc + review.rating, 0);
        return Number((sum / reviews.length).toFixed(1));
      },

      getReviewCount: (userId) => {
        return get().getReviewsForUser(userId, 'received').length;
      },

      getRecentReviews: (userId, limit = 5) => {
        return get()
          .getReviewsForUser(userId, 'received')
          .slice(0, limit);
      },

      getBuyerReviews: (userId) => {
        return get().reviews.filter(
          review => review.revieweeId === userId && review.type === 'buyer'
        );
      },

      getSellerReviews: (userId) => {
        return get().reviews.filter(
          review => review.revieweeId === userId && review.type === 'seller'
        );
      }
    }),
    {
      name: 'reviews-storage',
      version: 1
    }
  )
);